.preloader {
    position: fixed;
    top: 0;
    background: $color__white;
    height: 100vh;
    width: 100%;
    z-index: 1045;
    transition: 1s all;
    opacity: 1;
    visibility: visible;
    &__content {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 100%;
    }
    &__pulse {
        animation: pulse 2s infinite;
        border-radius: 100%;
        box-shadow: 0 0 0 rgba(204,169,44, 0.4);
        width: 150px;
        height: 150px;
        .icon-fluid {
            max-width: 100%;
            height: auto;

            animation: animate_grow 2s infinite;
        }
    }
}

.animate-grow:hover {
	animation: none;
}
 
@keyframes animate_grow {
	0% {
		transform: scale3d(1, 1, 1);
	}
	50% {
		transform: scale3d(1.05, 1.05, 1.05);
	}
	100% {
		transform: scale3d(1, 1, 1);
	}
}
.pulse:hover {
    animation: none;
  }

  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(68, 204, 44, 0.4);
      box-shadow: 0 0 0 0 rgba(76, 204, 44, 0.4);
    }
    70% {
        -moz-box-shadow: 0 0 0 10px rgba(44, 204, 71, 0);
        box-shadow: 0 0 0 100px rgba(44, 204, 44, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(49, 204, 44, 0);
        box-shadow: 0 0 0 0 rgba(57, 204, 44, 0);
    }
  }
.done {
    opacity: 0;
    visibility: hidden;
}