.info {
    position: absolute;
    bottom: 0;
    background: $color__white;

    @include media-breakpoint-between(md,xl) {
        @include py(54px);
        @include px(54px);
    }
    @include media-breakpoint-down(md) {
        @include py(32px);
        @include px(32px);
    }
    &__desc {
        @include media-breakpoint-between(md,xl) {
            @include py(32px,0);
        }
        @include media-breakpoint-down(md) {
            @include py(32px,0);
        }
    }
}

.info__col {
    @include media-breakpoint-between(md,xl) {
        @include fw(100%/12*6.2);
    }
    @include media-breakpoint-down(md) {
        @include fw(100%/12*8);
    }
    @include media-breakpoint-between(xs,sm) {
        @include fw(100%/8*8);
    }
}