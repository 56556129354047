body {
    font-family:$font-family_default;
    font-size: $font-size_default;
    line-height: $line-height_default;
    color: $color__black;
    background: $color__white;
    position: relative;
    overflow-x: hidden;
}

input {
    outline: 0;
    outline: none;
}

button {
    outline: 0;
    cursor: pointer;

    &:hover,
    &:focus {
        outline: 0;
        cursor: pointer;
    }
}

input, textarea {
    outline:none;
}
input:active, textarea:active {
    outline:none;
}
:focus {
    outline:none;
}
textarea {
    resize:none;
}
textarea {
    resize:vertical;
}
textarea {
    resize:horizontal;
}
ul {
    margin-bottom: 0;
}
h1 , h2 , h3 , h4 {
    margin-bottom: 0;
} 
a:hover {
    text-decoration: none;
    color: $color__white;
}
a {
    cursor: pointer;
    transition: all .3s ease;
}
p {
    @include py(16px,0);
    margin-bottom: 0;
}
.container {
    @extend %container;
    position: relative;
    @include media-breakpoint-down(xl) {
       
    }
    @include media-breakpoint-between(md,xl) {
        @include px(25px);
    }
 
    @include media-breakpoint-down(sm) {
         overflow: hidden;
    }
    @include media-breakpoint-down(md) {
        @include px(25px);
    }
    @include media-breakpoint-down(xs) {
        @include px(12px);
    }
}

.container-popup {
    @include media-breakpoint-between(md,xl) {
        @include px(54px);
    }
    @include media-breakpoint-between(md,xl) {
        @include px(25px);
    }
 
    @include media-breakpoint-down(sm) {
         overflow: hidden;
    }
    @include media-breakpoint-down(md) {
        @include px(25px);
    }
    @include media-breakpoint-down(xs) {
        @include px(12px);
    }
}

.icon-fluid {
    width: 100%;
    height: 100%;
}
.animate {
    overflow: hidden;
    background: linear-gradient(108.45deg, #167037 11.98%, #282D3C 95.31%);
}
.animate-bg  {
    background: linear-gradient(108.45deg, #167037 11.98%, #282D3C 95.31%);
    width: 100%;
    height: 100%;
}

.text-uppercase {
    text-transform: uppercase;
}

.pg-canvas {
    position: absolute;
    top: 0;
}

#particles {
    overflow: hidden;
    background: linear-gradient(108.45deg, #167037 11.98%, #282D3C 95.31%);
    z-index:0
}

.map--hidden {
   display: block;
}


.simplebar-content {
    height: 800px;
    display: flex;
    flex-wrap: wrap;
}

.simplebar-content {
    background: #fff;
    //overflow-y: hidden;
}
.simplebar-scrollbar::before {
    background-color: #fff;
}

.simplebar-track.simplebar-horizontal {
    display: none;
    background: #fff;
 }
.parallax {
    position: relative;
    background: transparent;
}
.parallax-bg {
    width: 100%;
    position: relative;
    padding: 40px 40px 64px;
    height: auto !important;
    display: flex;
    flex-direction: column;

    @media (max-width: 768px) {
        padding: 32px 20px 64px;
    }

    .info-block {
        display: flex;
        justify-content: space-between;
        padding-top: 40px;

        @media (max-width: 1444px) {
            flex-direction: column;
        }

        .info-block__left {
            width: 70%;
            padding-right: 20px;

            .h6 {
                font-size: 14px;
            }
            @media (max-width: 1444px) {
                width: 100%;

                .h3 {
                    font-size: 28px;
                }
            }

            @media (max-width: 1024px) {

                .h3 {
                    font-size: 24px;
                }
            }

            @media (max-width: 767px) {
                font-size: 20px;
            }
        }

        .info-block__right {
            width: 30%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-end;

            .info__desc {
                padding-top: 16px;
                border-top: 1px solid #ccc;
                margin-bottom: 20px;
            }

            .btn {
                width: 100%;
            }

            @media (max-width: 1444px) {
                width: 100%;
                flex-direction: row-reverse;
                align-items: flex-start;
                padding-top: 20px;
                margin-top: 20px;
                
                .info__desc {
                    margin: 0;
                    max-width: 50%;
                }

                .btn {
                    width: auto;
                }
            }


            @media (max-width: 768px) {
                flex-direction: column-reverse;
                margin-top: 0;
                padding-top: 16px;

                .btn {
                    margin-bottom: 32px;
                }
            }
        }
    }
}
.popup__slider {
    overflow: hidden;
}
.sticky {
   // transform: translate(100px);
    @include media-breakpoint-between(lg,xl) {
        transition: all 1s;
        position: sticky;
        top: 0;
        height: 100vh;
        //width: 46%;
        max-width: 100%;
      // width: 100%;
        //height: 100%;
        .img-fluid {
            width: 100%;
            height: 100%;
           //height: 1100px;
        }
    }
}
.sticky-bg {
 //   opacity: 0;
    transition: all 1s;
}
.text__green {
    color: $color__green-tab;
}
.post {
    &__link {
        display: block;
        color: $color__green-tab;
        @include  fs-lh(16px,22px);
        @include media-breakpoint-between(xs,xl) {
            @include py(8px,16px);
        }
        &:hover {
            color: $color__green-tab;
        }
    }
}

.post-info {
    background: $color__blue;
    @include media-breakpoint-between(sm,xl) {
        @include py(54px);
        @include px(54px);
    }
    @include media-breakpoint-down(sm) {
        @include py(32px);
        @include px(12px);
    }
}

.teacher {
    &__wrapper {
        @include media-breakpoint-between(md,xl) {
            display: flex;
            justify-content: space-between;
        }
    }
    &__top {
        @include py(0,8px);
        @include media-breakpoint-between(xs,xl) {
            display: flex;
        }
    }
    &__preview {
        @include wh(78px,78px);
    }
    &__name {
        @include media-breakpoint-between(xs,xl) {
            display: flex;
            align-items: center;
            @include px(16px,0);
        }
    }
    &__tell {
        display: block;
        @include  fs-lh(16px,22px);
        @include py(8px,0);
        font-weight: bold;
        color: $color__black;
        &:hover {
            color: $color__black;
        }
    }
    &__email {
        display: block;
        @include  fs-lh(16px,22px);
        color: $color__black;
        @include py(8px,0); 
        &:hover {
            color: $color__black;
        }
    }
    &__text {
        @include  fs-lh(16px,22px);
        @include py(8px,0); 
    }
    .btn {
        @include my(32px,0); 
    }
}

.teacher__col {
    &_left {
        @include media-breakpoint-between(md,xl) {
            @include fw(100%/12*4.5);
        }
    }

    &_right {
        @include media-breakpoint-between(md,xl) {
            @include fw(100%/12*4.5);
        }
    }
}
.modal {
    .fade {
        height: auto;
    }
    .show {
        height: 100vh;
    }
    
}

//section company
.company {
    @include media-breakpoint-between(lg,xl) {
        @include py(0, 80px);
    }

    @include media-breakpoint-down(md) {
        @include py(0, 60px);
    }

    @include media-breakpoint-down(sm) {
        @include py(0, 32px);
    }

    .piramide__h3 {
        padding-top: 0;
    }
}