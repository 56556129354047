.page-about {
    background: $color__white;
    height: 100%;
    @include media-breakpoint-between(lg,xl) {
        display: flex;
    }
    .content {
        @include media-breakpoint-between(lg,xl) {
            @include fw(100%/12*5.5);
        }
    }
    .post {
        @include media-breakpoint-between(lg,xl) {
            @include fw(100%/12*6.5);
        }
    }
    .sticky-bg {
        @include media-breakpoint-down(md) {
            height: 500px;
            .img-fluid {
                width: 100%;
                object-fit: cover;
                height: 100%;
            }
        }
        @include media-breakpoint-down(sm) {
            height: 300px;
            .img-fluid {
                width: 100%;
                object-fit: cover;
                height: 100%;
            }
        }
    }
}

.about-company {
    @include media-breakpoint-between(md,xl) {
        @include py(40px,54px);
        @include px(54px);
    }
    @include media-breakpoint-down(md) {
        @include py(40px,54px);
        @include px(36px);
    }
    @include media-breakpoint-down(sm) {
        @include py(40px,54px);
        @include px(12px);
    }
    &__wrapper {
        @include media-breakpoint-between(xs,xl) {
            @include py(42px,20px);
            display: flex;
            justify-content: space-between;
        }
        @include media-breakpoint-down(sm) {
            flex-direction: column;
        }
    }
    &__col {
        &_left {
            @include fw(100%/12*4);
        }
        &_right {
            @include media-breakpoint-between(sm,xl) {
                @include fw(100%/12*7);
            }
            @include media-breakpoint-down(sm) {
                @include fw(100%/8*8);
            }
            p {
                @include media-breakpoint-between(md,xl) {
                    @include my(0, 20px);
                }
            }
        }
    }
    .btn {
        @include media-breakpoint-between(xs,xl) {
            @include my(16px,0);
        }
    }
}