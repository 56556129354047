.article  {
    @include media-breakpoint-between(md,xl) {
        @include py(36px);
        @include px(54px);
    }
    @include media-breakpoint-down(md) {
        @include py(36px, 0);
        @include px(36px);
    }
    @include media-breakpoint-down(sm) {
        @include py(36px, 0);
        @include px(12px);
    }
    p {
        padding: 0;
    }
    .mt {
        @include my(20px,0);
    }
    .btn {
        @include media-breakpoint-between(xs,xl) {
            @include my(16px,0);
        }
    }
}

.article-news {
    @include py(40px,0);
}

.article__social {
    @include py(24px,0);
    @include media-breakpoint-between(xs,xl) {
        display: flex;
    }
    .social__item {
        display: block;
        @include mx(0,16px);
        color: $color__black;
        @include  fs-lh(16px,22px);
        transition: all 0.5s;
        .icon-fluid {
            position: relative;
            top: -5px;
            @include wh(7px,7px);
        }
        &:hover {
            color: $color__green-tab;
            fill: $color__green-tab;
        }
    }
}

.article-content {
    @include py(24px,0);
    .pt {
        padding-top: 0;
    }
}

.article__group {
    @include py(24px,0);
    display: flex;
    .article__col {
        @include media-breakpoint-between(xs,xl) {
            @include fw(100%/12*3);
            @include mx(0,54px);
        }
    }
}