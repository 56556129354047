//OpenSans
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800;900&display=swap');

@font-face {
    font-family: 'OpenSans';
    src: local('OpenSans Regular'),
        local('OpenSans-Regular'),
        url('../fonts/OpenSans-Regular.ttf') format('ttf');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'OpenSans';
    src: local('OpenSans Medium'),
        local('OpenSans-Medium'),
        url('../fonts/OpenSans-Medium.ttf') format('ttf');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'OpenSans';
    src:  local('OpenSans SemiBold'),
        local('OpenSans-SemiBold'),
        url('../fonts/OpenSans-SemiBold.ttf') format('ttf');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'OpenSans';
    src: local('OpenSans Bold'),
        local('OpenSans-Bold'),
        url('../fonts/OpenSans-Bold.ttf') format('ttf');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

//Relaway

@font-face {
    font-family: 'Raleway';
    src: local('Raleway Light'),
        local('Raleway-Light'),
        url('../fonts/Raleway-Light.ttf') format('ttf');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Raleway';
    src: local('Raleway Regular'),
        local('Raleway-Regular'),
        url('../fonts/Raleway-Regular.ttf') format('ttf');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Raleway';
    src: local('Raleway Medium'),
        local('Raleway-Medium'),
        url('../fonts/Raleway-Medium.ttf') format('ttf');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Raleway';
    src: local('Raleway SemiBold'),
        local('Raleway-SemiBold'),
        url('../fonts/Raleway-SemiBold.ttf') format('ttf');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Raleway';
    src: local('Raleway Bold'),
        local('Raleway-Bold'),
        url('../fonts/Raleway-Bold.ttf') format('ttf');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Golos-Regular';
    src: local('Golos Regular'),
        local('Golos-Regular'),
        url('../fonts/Golos-Regular.ttf') format('ttf');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Morena-Light';
    src: local('Morena Light'),
        local('Morena-Light'),
        url('../fonts/Morena-Light.otf') format('otf');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}