.popup-form {
    position: fixed;
    height: 588px;
    width: 433px;
    bottom: 54px;
    right: 54px;
    background: #fff;
    border: 1px solid #167038;
    opacity: 0;
    pointer-events: none;
    touch-action: none;
    visibility: hidden;
    transition: opacity 0.3s ease 0s, margin 0.3s ease 0s, visibility 0.3s ease 0s;
    background-color: rgb(255, 255, 255);
    transform: translate3d(0px, 0px, 0px);

    @include media-breakpoint-down(md) {
        bottom: 24px;
        right: 24px;
    }

    @include media-breakpoint-down(sm) {
        position: fixed;
        height: 100%;
        width: 100%;
        right: 0;
        border: 1px solid transparent;
        top: 70px;
    }
    .popup-close_text {
        padding: 11px;
        right: 36px;
        background-color: #167038;
        @include media-breakpoint-down(sm) {
            top: 16px;
            right: 16px;
    }
}
    .popup-close_text .icon-fluid {
        width: 15px;
        height: 15px;
    }
    .popup-close-btn {
        background-color: transparent;
        border: 0;
        outline: 0;
        padding: 0;
        cursor: pointer;
    }
    .form-box {
        padding: 87px 32px 32px 32px;
        text-align: left;
        @include media-breakpoint-down(sm) {
            padding: 71px 16px 16px;
        }
    }
    .form-box__button {
        @include media-breakpoint-down(xs) {
            justify-content: end;
        }
    }
    .form-box__h2 {
        font-size: 24px;
        line-height: 28px;
        color: #000000;
        margin-bottom: 32px;
    }
    .form-box__items {
        margin-bottom: 15px;
    }
    .label-checkbox {
        display: flex;
        align-items: baseline;
    }
    .form-select {
        option {
            width: 200px;
        }
    }
    .form-box__items input[type=checkbox]+label:before {
        margin-right: 8px;
    }
    .btn {
        padding: 16px 40px;
    }
    .form-box__wrap {
        @include media-breakpoint-down(sm) {
            margin: 0;
            max-width: 100%;
        }
       
    }

}
.popup-form_active {
    opacity: 1;
    z-index: 103;
    transition: opacity 0.3s ease 0s, margin 0.3s ease 0s, visibility 0.3s ease 0s;
    background-color: rgb(255, 255, 255);
    transform: translate3d(0px, 0px, 0px);
    opacity: 1;
    pointer-events: all;
    visibility: visible;
    touch-action: auto;
}
.popup-form-open {
    background-color: transparent;
    border: 0;
    outline: 0;
    padding: 0;
    cursor: pointer;
    position: fixed;
    bottom: 155px;
    right: 54px;
    z-index: 102;
    height: 75px;
    width: 75px;
    @include media-breakpoint-down(sm) {
        right: 16px;
    }
}