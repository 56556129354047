.clients {
    @include media-breakpoint-between(lg,xl) {
        @include py(80px,60px);

        .text-template {
            margin-bottom: 40px;
            width: 23%;
        }
    }
    @include media-breakpoint-down(md) {
        @include py(60px);
    }
    @include media-breakpoint-down(sm) {
        @include py(32px);
    }
    .text-template {
        @include media-breakpoint-down(md) {
            @include my(32px);
            width: 40%;
        }
        @include media-breakpoint-down(sm) {
            @include my(24px);
            @include fs-lh(18px,24px);
            width: 100%;
        }
    }
    li {
        list-style-type: none;
    }
}

.clients__wrapper {
    @include media-breakpoint-between(md,xl) {
        display: flex;
        justify-content: space-between;
    }
    @media (max-width: $tablet-s) {
        display: none;
    }
}
.clients__col {
    &_left {
        @include media-breakpoint-between(lg,xl) {
            @include fw(100%/12*5);
        }
        @include media-breakpoint-down(md) {
            @include fw(100%/12*9);
        }
        @include media-breakpoint-down(sm) {
            @include fw(100%/8*8);
        }
    }
    &_right {
        @include media-breakpoint-between(lg,xl) {
            @include fw(100%/12*2);
            position: relative;
        }
        @include media-breakpoint-down(md) {
           display: none;
        }
    }
}

.clients__card {
    &_prew {
        display: block;
        max-width: 209px;
        width: 100%;
        height: auto;
        text-align: center;
        @include py(38px,56px);
        background: $color__white;
        box-shadow: 2px 2px 10px rgba(60, 114, 193, 0.3);
    }
    &_descr {
        @include my(24px);
    }
    &_slogan {
        position: absolute;
        bottom: 0;
        @include py(16px,0);
        border-top: 1px solid $color__grey;
        color: $color__light_grey;
    }
}

.clients__links  {
    display: none;
    @include media-breakpoint-down(sm) {
        display: block;
        .tab-clients__item {
            padding-right: 0;
        }
    }
}