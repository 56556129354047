%container {
    @include mx(auto);
    max-width: 1320px;
}

%d-flex {
    display: flex;
    flex-wrap: wrap;
}

%flex-item {
    display: flex;
    align-items: center;
}

%flex-column {
    display: flex;
    flex-direction: column;
}

%btn-default {
    @include inline-block;
    border: 0;
    outline: 0;
    padding: 0;
    cursor: pointer;
    background: none;
}

%link-default {
    text-decoration: none;

    &:hover,
    &:focus {
        text-decoration: none;
    }
}

%list-default {
    list-style-type: none
}

%text-default {
    @include fs-lh(18px,25px);
    font-weight: normal;
}

%zi-1 {
    z-index: 1;
    position: relative;
}

%zi-2 {
    z-index: 2;
    position: relative;
}