.location {
    position: relative;
    width: 100%;
    display: block;
    height: 900px;
    &-bg {
        position: relative;
        background: rgba(235, 240, 243, 0.7);
        backdrop-filter: blur(5px);
        width: 100%;
        height: 900px;
        z-index: 10;
        @include media-breakpoint-down(md) {
            height: 580px;
        }
        @include media-breakpoint-down(sm) {
            height: 630px;
        }
    }
    @include media-breakpoint-down(md) {
        max-height: 580px;
        height: 100%;
     }
     @include media-breakpoint-down(sm) {
        max-height: 630px;
        height: 100%;
     }
    &-map {
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 9;
    }
    &__content  {
        @include media-breakpoint-between(lg,xl) {
            @include py(80px, 204px);
        }
        @include media-breakpoint-down(md) {
            @include py(60px,32px);
        }
    }

    &__h2 {
        color: $color__black;
        @include media-breakpoint-down(md) {
            @include fs-lh(40px,46px);
        }
    }
    &__h4 {
        @include media-breakpoint-down(sm) {
            @include fs-lh(24px,28px);
        }
    }
    &__about {
        @include media-breakpoint-between(lg,xl) {
            @include py(80px,0);
        }
        @include media-breakpoint-down(md) {
            @include py(32px,0);
        }
    }
    &__company {
        color: $color__black;
        @include media-breakpoint-down(md) {
            @include fs-lh(24px,28px);
        }
    }
    &__phone {
        @include py(18px,0);
        display: flex;
        color: $color__black;
        &_img {
            margin-right: 8px;
            position: relative;
            bottom: 4px;
            width: 20px;
            height: 20px;
            .icon-fluid {
                width: 100%;
                height: 100%;
            }
        }
        @include media-breakpoint-down(sm) {
            font-weight: 400;
        }
    }
    &__phone:hover { 
        color: $color__green;
    }
    &__site {
        @include py(11px,0);
        display: block;
        color: $color__black;
        @include media-breakpoint-down(sm) {
            font-weight: 400;
        }
    }
    &__site:hover { 
        color: $color__green;
    }
    &__address {
        @include py(64px,0);
        border-top: 1px solid rgba(40, 45, 60, 0.5);
        &_text {
           // width: 22%;
            color: $color__black;
            font-weight: 400;
        }
        @include media-breakpoint-down(md) {
            @include py(32px,60px);
            &_text {
                width: 100%;
                color: $color__black;
            }
        }
    }
    .btn {
        @include media-breakpoint-between(lg,xl) {
            position: absolute;
            right: 0;
            bottom: 0;
        }
        @include media-breakpoint-down(md) {
            position: absolute;
            right: 0;
            bottom: 0;
        }
        @include media-breakpoint-down(sm) {
            position: relative;
            @include my(32px,0);
        }
    }
}

.location__wrapper  {
    @include media-breakpoint-between(lg,xl) {
        @include py(0, 80px);
        display: flex;
        justify-content: space-between;
    }
    @include media-breakpoint-down(md) {
        @include py(0,60px);
        display: flex;
        justify-content: space-between;
    }
    @include media-breakpoint-down(sm) {
        @include py(0px,32px);
        flex-direction: column;
    }
}

.location__col {
    &_left {
        @include media-breakpoint-between(lg,xl) {
            @include fw(100%/12*6);
        }
        @include media-breakpoint-down(md) {
            @include fw(100%/8*3);
        }
        @include media-breakpoint-down(sm) {
            @include fw(100%/8*8);
        }
    }
    &_right {
        @include media-breakpoint-between(lg,xl) {
            @include fw(100%/12*3.7);
            position: relative;
        }
        @include media-breakpoint-down(md) {
            @include fw(100%/8*3);
            position: relative;
        }
        @include media-breakpoint-down(sm) {
            @include fw(100%/8*8);
            position: relative;
        }
    }
}

.location-map {
    @include media-breakpoint-between(lg,xl)  {
        width: 100%;
        height: 900px;
    }
    @include media-breakpoint-down(md) {
        height: 580px;
     }
    @include media-breakpoint-down(sm) {
        height: 630px;
    }
}

.location__close  {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 74px;
    width: 74px;
    background: #167038;
    opacity: 0;
    position: absolute;
    right: 27px;
    top: 27px;
    z-index: 10;
    cursor: pointer;

    .icon-fluid {
        display: block;
        @include wh(23px,23px);
    }
    @include media-breakpoint-down(md) {
        height: 39px;
        width: 39px;
        right: 12px;
        top: 16px;

        .icon-fluid {
            @include wh(12px,12px);
        }
    }
}
.visible {
    opacity: 1;
}
.opacity-0 {
    opacity: 0;
}
.map-1 {
    opacity: 1;
}
.map-2 {
    opacity: 1;
}