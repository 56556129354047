.swiper-content {
    //position: relative;
   @include media-breakpoint-between(md,xl) {
       overflow: hidden;
    }
    @include media-breakpoint-down(sm) {
        overflow: visible;
    }
}
.swiper-slide {
    //width: auto!important;
}
.swiper-slide-active {
    opacity: 1!important;
}

.swiper-slide-thumb-active {
    color: $color__white;
}

.swiper-pagination {
    top: 195px;
    width: 6%;
    right: 40px!important;
    @include media-breakpoint-down(lg) {
        right: 50px!important;
        width: 7%;
    }
    @include media-breakpoint-down(md) {
        right: 45px!important;
        width: 9%;
    }
    @include media-breakpoint-down(sm) {
        display: none;
    }
}

.swiper-pagination-custom {
    left: auto;
}

.tab-news__nav {
    position: relative;

}

.swiper-pagination-current {
    font-weight: 700;
    @include fs-lh(24px,22px);
    @include media-breakpoint-down(sm) {
        @include fs-lh(16px,21px);
    }
}

.swiper-pagination-total {
    font-size: 16px;
    line-height: 21px;
}

.btn-swiper {
    position: absolute;
    top: 195px;
    z-index: 10;
    @extend %btn-default;
    .icon-fluid {
        width: 12px;
        height: 12px;
    }
    @include media-breakpoint-down(sm) {
        //top: 225px;
        display: none;
    }
}
.swiper-nav__arrow-prev  {
    right: 15px;
}
.swiper-nav__arrow-next {
    right: 140px;
    @include media-breakpoint-down(md) {
        right: 150px;
    }
}

.popup__swiper {
    @include media-breakpoint-between(md,xl) {
        @include py(80px,99px);
    }
    @include media-breakpoint-between(xs,md) {
        @include py(37px,54px);
    }
    .title__active{
        @include media-breakpoint-between(md,xl) {
            @include my(0,32px);
        }
        @include media-breakpoint-between(xs,md) {
            @include my(0,24px);
        }
    }
    .swiper-slide {
        //margin-right: 30px;
    }
}
.promo-swiper {
    width: 100%;
    height: 100%;
    .swiper-slide {
        opacity: 0;
    }
    .swiper-slide-prev {
        opacity: 0!important;
    }
    .swiper-slide-next {
        opacity: 0!important;
    }
}

.popup__list {
    overflow: hidden;
    position: relative;
    padding-bottom: 80px;

    @media(max-width: 1024px) {
        padding-bottom: 60px;
    }

    @media(max-width: 768px) {
        padding-bottom: 32px;
    }

    .btn-swiper {
        top: 2.5px;
    }

    .swiper-pagination {
        @include media-breakpoint-up(md) {
            top: 3px;
            display: flex;
            height: 23px;
            align-items: baseline;
            width: 58px;
            justify-content: space-between;
        }

    }
    .swiper-nav__arrow-next {
        @include media-breakpoint-up(md) { 
            right: 114px;
        }

        
    }
}
.popup__items {
    margin-bottom: 20px;
    padding-bottom: 20px;
}
