.card-info {
    @include media-breakpoint-between(md,xl) {
        @include my(40px,0);
    }
    @include media-breakpoint-down(sm) {
        @include my(32px,0);
    }
    &__block  {
        background: $color__blue;
        max-width: 320px;
        width: 100%;
        height: auto;
        @include media-breakpoint-between(xs,xl) {
            min-height: 216px;
            @include py(40px);
            @include px(40px);
        }
    }

    &__number {
        color: $color__black;
        .plus {
            @include mx(10px,0);
        }
        @include media-breakpoint-between(lg,xl) {
            @include fs-lh(60px,82px);
            font-weight: normal;
            .padding-r {
                padding-right: 10px;
            }
            .color-green {
                font-weight: 600;
            }
        }
        @include media-breakpoint-between(xs,md) {
            @include fs-lh(50px,68px);
            .color-green {
                font-weight: 400;
            }
        }
    }
    .text-template {
        margin: 0;
    }
}

.card-info__wrapper {
    @include media-breakpoint-between(lg,xl) {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    @include media-breakpoint-down(lg) {
        gap: 16px;
    }
    @include media-breakpoint-down(md) {
        display: flex;
        gap: 16px;
        flex-wrap: wrap;
    }
    @include media-breakpoint-down(sm) {
        display: flex;
        gap: 16px;
        flex-wrap: wrap;
    }
    @include media-breakpoint-down(xs) {
        flex-direction: column;
    }
}
.card-info__col {
    &_tmpl {
        //@include media-breakpoint-between(lg,xl) {
       //     @include fw(100%/12*2.8);
       //     margin-right: 16px;
       // }
        @include media-breakpoint-down(xl) {
            @include fw(100%/12*2.8);
            margin-right: 16px;
        }
        @include media-breakpoint-down(lg) {
            @include fw(100%/12*3.8);
            margin-right: 0;
        }
        @include media-breakpoint-down(md) {
            @include fw(100%/12*5.8);
        }
        @include media-breakpoint-down(sm) {
            @include fw(100%/12*5.8);
            margin-right: 0px;
        }
        @include media-breakpoint-down(xs) {
            @include fw(100%/8*8);
            margin-right: 0px;
        }
    }
}

.card-news {
    @include media-breakpoint-down(xl) {
        //display: flex;
       // flex-direction: row;
       // flex-wrap: wrap;
        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 16px;
        column-gap: 16px;
        margin-top: 32px;
        grid-template-rows: auto auto auto auto;
        .img-fluid {
            max-width: fit-content;
        }
    }
    @include media-breakpoint-down(lg) {
        //display: flex;
       // flex-direction: row;
       // flex-wrap: wrap;
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 16px;
        column-gap: 16px;
        margin-top: 32px;
        grid-template-rows: auto auto auto auto;
        .card-news__bg {
            .img-fluid {
                width: 100%;
            }
        }
    }
    @include media-breakpoint-down(lg) {
        //grid-template-rows: 430px;
    }
    &__item {
        position: relative;
        cursor: pointer;
    }
    &__bg {
        @include media-breakpoint-down(lg) {
            .img-fluid {
                max-width: initial;
                width: auto;
                height: 100%;
                object-fit: cover;
            }
        }
        @include media-breakpoint-down(md) {
            width: 100%;
            .img-fluid {
                object-fit: none;
            }
        }
        @include media-breakpoint-down(sm) {
            max-width: 100%;
            .img-fluid {
                object-fit: cover;
                width: 100%;
                height: 320px;
            }
        }
    }
    &__item-resize  {
        cursor: pointer;
        @include media-breakpoint-down(xl) {
           // max-height: 312px;
            overflow: hidden;
        }
        @include media-breakpoint-down(lg) {
            max-height: 300px;
            height: 100%;
            overflow: hidden;
        }
        @include media-breakpoint-down(sm) {
            width: 100%;
        }
    }
    &__content {
        position: absolute;
        top: 0;
        left: 7px;
        height: 100%;
        width: 100%;
       // z-index: 10;
        @include media-breakpoint-between(md,xl) {
           @include py(24px,24px);
           @include px(16px,24px);
        }
        @include media-breakpoint-between(xs,sm) {
           left: 0;
           @include py(24px);
           @include px(18px);
        }
        &_bottom {
            position: absolute;
            bottom: 24px;
            z-index: 10;
        }
    }
    .h4 {
        color: $color__white;
        position: relative;
        z-index: 10;
    }
    &__h2 {
        color: $color__white;
        font-weight: normal!important;
        @include media-breakpoint-down(sm) {
            @include fs-lh(60px!important,82px!important);
        }
    }
    &__text {
        color: $color__white;
        @include media-breakpoint-between(md,xl) {
            @include fs-lh(16px,22px);
            display: flex;
            align-items: center;
            height: 60%;
        }
    }
    &__mounth {
        color: $color__white;
        font-weight: 300;
    }
    .swiper-slide {
        width: auto!important;
    }
}
.card-news__descr {
    display: block;
    position: relative;
    z-index: 10;
    color: $color__white;
    font-weight: 300;
    @include fs-lh(16px,22px);
    @include media-breakpoint-between(md,xl) {
        //@include py(16px,0);
    }
}
.card-news__descr_before {
    &::before {
        content: '';
        position: absolute;
        left: -12px;
        bottom: 8px;
        background: $color__white;
        border-radius: 21px;
        width: 4px;
        height: 4px;
    }
}
.card-news__descr_icon {
    width: 7px;
    height: 13px;
    margin-right: 8px;
    .icon-fluid {
        position: relative;
        bottom: 2px;
        width: 100%;
        height: 100%;
    }
}

.card-news__descr_ml {
    margin-left: 12px;
}

.card-news__descr_flex {
    display: flex;
}
.card-news__overflow {
    height: 720px;
    overflow: hidden;
}

.card-news__data {
    display: flex;
    justify-content: space-between; 
    position: relative;
    z-index: 10;
}

.card-news__bg  {
    position: relative;
    z-index: 9;
    margin-right: 3px;
    transition: all 1s ease;
    background: #007136;
    background-position: 100% 100%;
    background-blend-mode: luminosity;
    mix-blend-mode: multiply;
    .img-fluid {
        transition: all 1s ease;
        background: #007136;
        background-position: 100% 100%;
        background-blend-mode: luminosity;
        mix-blend-mode: multiply;
        transition: filter 0.5s linear;
        filter: brightness(0.5) grayscale(50%) opacity(0.5);
        filter: blur(2px);
        &:hover {
            mix-blend-mode: initial;
            filter: blur(0px);
        }
    }
}

.card-news__item:hover .img-fluid {
    mix-blend-mode: initial;
    filter: blur(0px);
}
    
.card-partners_position {
        .project__position {
            @include media-breakpoint-only(lg) {
                top: 110px!important;
            }
            
        }
        .card-news__bg  {
            background: none;
            .img-fluid { 
                filter: none;
            }
         
        }
    }
    
    
    .card-news__partners {
        @include wh(100%, 100px);
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        cursor: default;
        @include media-breakpoint-down(md) {
            height: 100%;
        }
    }
    
    .card-partners {
        .card-news {
            grid-template-columns: 1fr 1fr 1fr;
            margin-top: 68px;
        
            @include media-breakpoint-down(lg) {
                grid-template-columns: 1fr 1fr 1fr ;
            }
            @include media-breakpoint-down(md) {
                grid-template-columns: 1fr 1fr 1fr;
                row-gap: 70px;
            }
            @include media-breakpoint-down(sm) {
                grid-template-columns: 1fr 1fr 1fr;
            }
            @include media-breakpoint-down(xs) {
                grid-template-columns: 1fr 1fr;
            }
        }
    }
    .card-news__partners-img {
        filter: grayscale(100%);
        cursor: pointer;
        @include media-breakpoint-down(xs) {
            text-align: center;
        }
        .img-fluid {
            object-fit: contain;
            background: transparent;
            @include media-breakpoint-down(md) {
                background: none;
                height: 65px;
            }
        }
    }
            
    .card-news__partners-img:hover {
        filter: grayscale(0);
    }