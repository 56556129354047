.materials {
    @include media-breakpoint-between(xs,md) {
        @include my(24px,0);
    }
    @include media-breakpoint-between(md,xl) {
       display: flex;
       @include py(32px);
    }
    &__content {
        @include media-breakpoint-between(xs,md) {
            @include py(24px,0);
        }
    }
    &__icon {
        @include media-breakpoint-between(md,xl) {
            @include px(0,20px);  
            span {
                display: block;
                color: rgba(0, 0, 0, 0.5);  
                @include fs-lh(12px,16px);
            }
        }
        @include media-breakpoint-between(xs,md) {
            span {
                @include px(16px,0);
            }
        }
    }
    &__desc {
        color: rgba(0, 0, 0, 0.6);
        @include media-breakpoint-between(md,xl) {
            @include fs-lh(16px,22px);
            @include py(4px, 0);
        }
        @include media-breakpoint-between(xs,md) {
            @include py(4px, 0);
        }
    }
    &__dropdown {
        display: block;
        cursor: pointer;
        color: $color__green-tab;
        text-decoration: underline;
        @include fs-lh(12px,16px);
        @include media-breakpoint-between(md,xl) {
            @include py(10px, 0);
        }
        @include media-breakpoint-between(xs,md) {
            @include py(10px, 0);
        }
        &:hover {
            color: $color__green-tab;
        }
    }
}