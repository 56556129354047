//fonts
$font-family_default: 'Open Sans', sans-serif;
$font-family_title: 'Raleway', sans-serif;
$font-size_default: 18px;
$line-height_default: 25px;

//colors
$color__white: #fff;
$color__light_white: #F4F6FB;
$color__black: #000;
$color__title: #282D3C;
$color__grey:  #CCCCCC;
$color__light_grey:#818181; 
$color__green-btn: #167037;
$color__green-text:#7FB493;
$color__green-tab:  #167038;
$color__green:  #167038;
$color__blue:#F4F6FB;


$mobile: 320px;
$tablet-s: 576px;
$tablet-m: 768px;
$tablet-l: 992px;
$laptop-width: 1025px;
$desktop-s: 1200px;
$desktop-m: 1366px;
$desktop-grid: 1440px;
$desktop-l: 1680px;