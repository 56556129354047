input:-webkit-autofill {
  transition: all 5000s ease-in-out 0s;
}

.form-box {

    text-align: center;
    padding: 100px 0;
    @include media-breakpoint-down(md) {
        padding: 57px 0;
        margin: 0 auto;
    }
    @include media-breakpoint-down(sm) {
        padding: 32px 0;
        margin: 0 auto;
    }
    .label-checkbox {
        @include media-breakpoint-down(xs) {
            display: flex;
            width: 100%;

        }
    }

    .label-checkbox__icon {
        @include media-breakpoint-down(sm) {
            @include fs-lh(14px,19px);
        }
    }
}

.form-box__text {
    max-width: 433px;
   padding: 24px 20px 30px 20px; 
    margin: 0 auto;
    @media (max-width:$tablet-s) {
        padding: 24px 9px 30px;
    }

}

.form-box__wrap {
    text-align: left;
    max-width: 544px;
    margin: 0 auto;
    margin-bottom: 32px;
    @media (max-width:$tablet-s) {
       padding-left: 0;
       margin: 0;
    }

}


.form-box__items {
    margin-bottom: 32px;
}

.form-box__item {
    width: 100%;
    color: rgba(0, 0, 0, 0.5);
    font-weight: 300;
    border: none;
    border-bottom: 1px solid #D7D9DB;
    background: transparent;
    @include media-breakpoint-down(sm) {
        @include fs-lh(16px,22px);
    }
}

.form-box__req {
    position: relative;
    &::before {
        content: '*';
        position: absolute;
        top: 0;
        color: #167038;
        height: 1px;
        width: 1px;
    }
}
.form-box__item:focus {
    background: transparent;
}
.form-select {
    width: 100%;
    color: rgba(0, 0, 0, 0.5);
    font-weight: 300;
    border: none;
    border-bottom: 1px solid #D7D9DB;
    background: transparent;
}
.form-box__items {
    width: 100%;
    color: rgba(0, 0, 0, 0.5);
    font-weight: 300;
    border: none;
    background: transparent;
    display: inline-block;
    position: relative;
    @include media-breakpoint-down(sm) {
        margin-bottom: 24px;
    }
    a {
        @include media-breakpoint-down(sm) {
           border-bottom: 1px solid #167038;
        }
    }
  }
  
  .form-box__items input {
    background: transparent;
    border-bottom: 1px solid #D7D9DB;
  }
  
  .form-box__items input:valid + .placeholder {
    display: none;
  }
  .form-box__items input:hover + .placeholder {
    display: none;
  }
  .form-box__items input:focus + .placeholder {
    display: none;
  }
  .form-box__items input:active + .placeholder {
    display: none;
  }
  .form-box__items .placeholder {
    position: absolute;
    top: 1px;
    left: 2px;
    z-index: -1;
  }
  
  .form-box__items .placeholder::before {
    content: attr(data-placeholder);
    color: rgba(0,0,0,.5)
  }
  
  .form-box__items .placeholder::after {
    content: "*";
    color: #167038;
    font-weight: 400;
  }
  .form-box__items .placeholder:active {
    color: red;
  }
.form-box {

    input[type=checkbox],
    input[type=radio] {
        display: none !important;
    }

    input[type=checkbox]+label:before {
        content: "\2022";
        cursor: pointer;
        color: transparent;
        margin-right: 16px;
        display: inline-block;
        border: 1px solid rgb(0, 0, 0);
        border-radius: 2px;
        font-size: 20px;
        line-height: 12px;
        //margin: -5px 5px 0 0;
        height: 13px;
        width: 13px;
        text-align: center;
        vertical-align: middle;
        transition: color ease .3s;
        @media (max-width:$tablet-s) {
            font-size: 30px;
        }
    }

    input[type=checkbox]:checked+label:before {
        color: #167038;
        border-color: rgb(0, 0, 0);
        padding-bottom: -15px;
        height: 13px;
        width: 13px;
    }
    @media (max-width:$tablet-s) {
        max-width: 100%;
    }
}

.form-box__item-link {
    display: contents;
}

.form-box__items-checkbox {
    display: flex;
}

.form-box__item-link span {
    color: rgba(0, 0, 0, 0.5);
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
}

.form-box__item-link a {
    color: #167038;
    border-bottom: 1px solid #167038;
}

.form-box__button {
    display: flex;
    justify-content: end;
    @media (max-width:$tablet-s) {
        justify-content: flex-start;
        .btn-box {
            padding: 24px 64px;
        }
    }
}

.form-box__items {
    input[type=checkbox] + label:before {
        content: "\2022";
        cursor: pointer;
        color: transparent;
        margin-right: 16px;
        display: inline-block;
        border: 1px solid #000;
        border-radius: 2px;
        font-size: 20px;    
        line-height: 12px;
        padding: 0 2px 0 2px;
        //margin: -5px 5px 0 0;
        height: 13px;
        width: 13px;
        text-align: center;
        vertical-align: middle;
        transition: color ease .3s;
    }
    
    input[type=checkbox]:checked + label:before {
        color: #167038;
        border-color: #000;
        padding-bottom: -15px;
        height: 13px;
        width: 13px;
    }
    .label-checkbox__icon {
        color: rgba(0, 0, 0, 0.5);
    }
    a {
        display: contents;
        color: #167038;
        text-decoration: underline;
    }
}

.form-box__item-name::placeholder {
    opacity: 0;
}

.form-box__item-name:hover::placeholder {
    opacity: 1;
    color: rgba(0,0,0,.5);
}

.form-box__item-name:focus::placeholder {
    opacity: 1;
    color: rgba(0,0,0,.5);
}
