.piramide {
    overflow: hidden;
    &__h3 {
        display: inline-block;
        position: relative;
        white-space: pre-line;
        &:after {
            content: '';
            position: absolute;
            left: 0;
            bottom: -8px;
            background: linear-gradient(108.45deg, #167037 11.98%, #282D3C 95.31%);
            width: 98%;
            height: 6px;
            @include media-breakpoint-down(xs) {
                height: 3px;
                width: 100%;
            }
        }
        @include media-breakpoint-down(xl) {
            @include py(40px,0);
            @include my(0,8px);
            width: 38%;
        }
        @include media-breakpoint-down(lg) {
            @include py(60px,0);
            @include my(0,32px);
            width: 43%;
        }
        @include media-breakpoint-between(md,lg) {
            @include py(0px,0);
        }
        @include media-breakpoint-down(md){
            width: 50%;
        }
        @include media-breakpoint-down(sm) {
            padding-top: 0;
            width: 65%;
        }
        @include media-breakpoint-down(xs) {
            @include my(0);
            white-space: initial;
            padding-top: 0;
            width: 208px;
        }
    }
    .text-template {
        margin-top: 0px;
        @include media-breakpoint-down(sm) {
            margin-top: 32px;
            @include  fs-lh(18px, 22px);
        }
        @include media-breakpoint-down(xs) {
            margin-top: 24px;
        }
    }
    &__descr {
        position: relative;
        right: 100px;
        width: 60%;
        @include  fs-lh(16px, 22px);
    }
    @include media-breakpoint-down(sm) {
        @include py(40px,0);
    }
    @include media-breakpoint-down(xs) {
        @include py(24px,0);
    }
}

.piramide__wrapper {
    @include media-breakpoint-between(md,xl) {
        display: flex;
        justify-content: space-between;
        @include my(32px,0);   
    }
    @include media-breakpoint-down(sm) {
        @include my(32px,0); 
    }
    @include media-breakpoint-down(xs) {
        @include my(24px,0); 
    }
}

.piramide__col {
    @include media-breakpoint-between(lg,xl) {
        &_2 {
            @include fw(100%/12*2);
        }
        &_3 {
            @include fw(100%/12*3);
        }
        &_md_4 {
            @include fw(100%/12*4);
        }
        &_md_6  {
            @include fw(100%/12*6);
        }
        &_md_8  {
            @include fw(100%/12*8);
        }
        &_md_9  {
            @include fw(100%/12*9);
        }
        &_md_10  {
            @include fw(100%/12*10);
        }
        &_md_12  {
            @include fw(100%/12*12);
        }
    }
    @include media-breakpoint-between(md,lg) {
        &_2 {
            @include fw(100%/12*2);
        }
        &_3 {
            @include fw(100%/12*3);
        }
        &_md_4 {
            @include fw(100%/12*5);
        }
        &_md_6  {
            @include fw(100%/12*7.2);
        }
        &_md_8  {
            @include fw(100%/12*8);
        }
        &_md_9  {
            @include fw(100%/12*9);
        }
        &_md_10  {
            @include fw(100%/12*10);
        }
        &_md_12  {
            @include fw(100%/12*12);
        }
    }
    @include media-breakpoint-down(md) {
        &_3 {
            .text-template {
                display: none;
            }
        }
    }
    @include media-breakpoint-down(sm) {
        &_sm_3 {
            @include fw(100%/8*3);
        }
        &_sm_4  {
            @include fw(100%/8*4);
        }
        &_sm_5  {
            @include fw(100%/8*5);
        }
        &_sm_7  {
            @include fw(100%/8*7!important);
        }
    }
    @include media-breakpoint-down(xs) {
        &_sm_3 {
            @include fw(100%/8*4);
        }
        &_sm_4  {
            @include fw(100%/8*5);
        }
        &_sm_5  {
            @include fw(100%/8*6);
        }
        &_sm_7  {
            @include fw(100%/8*8!important);
        }
    }
}

.piramide__subtext  {
    display: none;
    @include media-breakpoint-down(md) {
        display: block;
    }
}

.d-flex {
    @include media-breakpoint-down(xs) {
        flex-direction: column-reverse;
    }
}

.align-items-center {
    @include media-breakpoint-down(xs) {
      align-items: flex-end!important;
    } 
}

.piramide__size1 {
    position: absolute;
    right: 0;
}
.piramide__size2 {
    position: absolute;
    right: 0;
    margin-top: 80px;
}
.piramide__title {
    @include title(24px,28px,700);
}
.piramide__item {
    position: absolute;
    right: 0;
    border-bottom: 50px solid $color__light_white;
    border-left: 50px solid transparent;
}
.item-1 {
    width: 55%;
}
.item-2 {
    width: 85%;
}

.zi-2 {
    z-index: 2;
    position: relative;
}

.section {
    height: 100vh;
}

/* feature */

.feature__content {
    font-size: 14px;
    line-height: 24px;
    display: flex;
    @include media-breakpoint-down(md) {
        position: relative;
        z-index: 10;
    }
    @include media-breakpoint-down(sm) {
        margin-right: -80px!important;
        width: 30%;
        z-index: 10;
    }
    @include media-breakpoint-down(xs) {
       display: flex;
       width: 70%;
       margin-top: 10px;
       margin-right: 80px!important;
    }
}

.feature__content.feature__content_col {
    @include media-breakpoint-between(md,xl) {
        width: auto;
        max-width: 20%;
        flex: 0 0 20%;
    }
    @include media-breakpoint-down(sm) {
       
    }
}

.feature__col.feature__col_wide {
    @include media-breakpoint-between(md,xl) {
        width: auto;
        max-width: 80%;
        flex: 0 0 80%;
    }
    @include media-breakpoint-down(sm) {
        @include fw(100%/8*6);
    }
}

.feature__title {
    @include title(24px,28px,700);
    text-align: right;
    padding: 16px;
    margin: 0 0 0 64px;
    position: relative;
    font-family: $font-family_title;
    color: #282D3C;
    @include media-breakpoint-down(md) {
       margin: 0;
    } 
    @include media-breakpoint-down(sm) {
        @include title(20px,23px,700);
    } 

}

.feature__title:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    transition: ease .3s all;
    background:  $color__light_white;
}

.feature__title:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: -64px;
    z-index: 1;
    transition: ease .3s all;
    border-style: solid;
    border-width: 0 0 60px 64px;
    border-color:   rgba(0,0,0,0) rgba(0,0,0,0)  $color__light_white;
    @include media-breakpoint-down(sm) {
        border-width: 0 0 54px 64px;
    }
    @include media-breakpoint-down(xs) {
        border-width: 0 0 54px 64px;
    }
}

.feature__title:hover:before {
    left: -15px;
}

.feature__title:hover:after {
    left: -79px;
}
