.modal {
	.h2 {
		color: $color__white;
	}
}

// .modal-glass {
//     width: 50%;
//     height: 100%;
//     backdrop-filter: blur(5px);

//     @media(max-width: 998px) {
//         display: none;
//     }
// }

// .modal-dialog {
// 	max-width: 100%;
// 	height: 100vh;
// 	margin: 0;
// }
// .modal-dialog {
// 	max-width: 50%;
// 	height: 100vh;
// 	margin: 0;
// 	@include media-breakpoint-down(md) {
// 		max-width: 100%;
// 	}
// }
.modal-content {
	position: relative;
	@include py(100px, 0);
	@include px(54px, 0);
	height: 100%;
	background: linear-gradient(
		108.45deg,
		rgba(22, 112, 55, 0.97) 11.98%,
		rgba(40, 45, 60, 0.97) 95.31%
	);
	@include media-breakpoint-down(sm) {
		@include py(100px, 0);
		@include px(24px, 0);
	}
	@include media-breakpoint-down(xs) {
		@include py(60px, 0);
		@include px(1px, 0);
	}
}
.modal-content-width {
	width: 50%;
	@include media-breakpoint-down(md) {
		width: 100%;
	}
}
.modal-head {
	position: absolute;
	right: 32px;
	top: 32px;
	cursor: pointer;
	z-index: 1;
	.icon-fluid {
		width: 23px;
		height: 23px;
	}
	@include media-breakpoint-down(sm) {
		right: 16px;
	}
	@include media-breakpoint-down(xs) {
		right: 16px;
	}
}
.form__field {
	padding-bottom: 8px;
}
.label__answer {
	position: relative;
	font-weight: 300;
	margin-left: 25px;
	color: $color__white;

	@include media-breakpoint-down(lg) {
		font-size: 16px;
	}

	&::before {
		content: "";
		position: absolute;
		left: -22px;
		top: 12px;
		background: $color__white;
		border-radius: 5px;
		width: 5px;
		height: 5px;
	}
}
.form {
	position: relative;
	@include py(48px, 0);
	@include media-breakpoint-down(lx) {
		width: 30%;
	}
	@include media-breakpoint-down(lg) {
		width: 60%;
	}
	@include media-breakpoint-down(sm) {
		width: 100%;
	}
	&__item {
		@include py(0, 15px);
	}
	input {
		width: 100%;
		color: $color__white;
		font-weight: 300;
		border: none;
		border-bottom: 1px solid rgba(255, 255, 255, 0.3);
		background: transparent;
		&::placeholder {
			color: $color__white;
		}
	}
	textarea {
		min-height: 80px;
		height: 100%;
		width: 100%;
		color: $color__white;
		border: none;
		border-bottom: 1px solid rgba(255, 255, 255, 0.3);
		background: transparent;
		font-weight: 300;
		&::placeholder {
			color: $color__white;
		}
	}
	.btn {
		position: absolute;
		right: 0;
	}
}
.form__mistake::before {
	border: 1px solid red !important;
}

.form__mistake {
	input {
		border-bottom: 1px solid rgb(255, 0, 0) !important;
	}
}
.modal-order {
	.modal-head {
		@include media-breakpoint-down(lg) {
			top: 16px;
		}
	}

	.modal-content {
		@include media-breakpoint-down(lg) {
			padding: 0 0 0 10px;
		}

		@include media-breakpoint-down(sm) {
			padding: 44px 0 0 1px;
		}

		.modal__h2 {
			@include media-breakpoint-down(lg) {
				font-size: 28px;
				line-height: 30px;
			}
		}
	}

	.form-check {
		@include my(15px, 0);
	}
	.form {
		width: 80%;

		@include media-breakpoint-down(lg) {
			padding-top: 16px;
		}

		@include media-breakpoint-down(xl) {
			width: 90%;
		}
		@include media-breakpoint-down(xs) {
			width: 100%;
		}
	}
	.form__item {
		@include media-breakpoint-down(lg) {
			padding-bottom: 12px;
		}

		textarea {
			max-height: 150px;
			resize: vertical;
		}
	}
	.form__item_flex {
		display: flex;
		@include media-breakpoint-down(lg) {
			flex-direction: column;
		}
	}
	.form__item_check {
		display: flex;
	}
	.form__field_phone {
		width: 38%;
		@include py(16px);
		@include px(20px);
		border-bottom: none !important;
		background: rgba(255, 255, 255, 0.3) !important;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
		margin-right: 20px;
		@include media-breakpoint-down(lg) {
			width: 100%;
		}
	}
	.btn {
		position: relative;
		max-width: 216px;
		width: 100%;
		@include media-breakpoint-down(lg) {
			max-width: 100%;
			width: 100%;
			margin-top: 24px;
		}
	}
	.input-checkbox {
		width: auto;
		height: 30px;
		display: flex;
		align-items: center;
		text-align: center;
	}
	.label-checkbox {
		@include px(0px, 0);
		width: 100%;
		color: $color__white;
		font-weight: 300;
		display: flex;
		@include media-breakpoint-up(md) {
			align-items: baseline;
		}
		@include media-breakpoint-down(lg) {
			width: 100%;
		}
	}
}
.form__item-text {
	color: #fff;
	width: 586px;
	@media (max-width: $tablet-s + 50) {
		width: 100%;
	}
}
.js-error {
	input {
		color: red !important;
	}
	border-bottom: 1px solid red !important;
}

.popup-close {
	cursor: pointer;
	position: absolute;
	right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #167038;
	@include wh(39px, 39px);
	@include my(22px, 0);
	@include mx(0, 22px);
	.icon-fluid {
		width: 23px;
		height: 23px;
	}
}

.popup-close_text {
	display: inline-flex;
	align-items: center;
    justify-content: center;
    width: 39px;
    height: 39px;
	position: absolute;
	top: 28px;
	right: 40px;
	cursor: pointer;
	font-weight: 700;
	font-size: 24px;
	line-height: 33px;
	color: #282d3c;
    background: #167038;
	@media (max-width: 768px) {
		right: 20px;
	}
	.icon-fluid {
		width: 23px;
		height: 23px;
		display: block;
	}

	span {
		@media (max-width: 768px) {
			font-size: 20px;
		}
	}
}
.modal-open {
	.header .container .header__wrapper {
		margin-left: -7.5px;
		margin-right: 7.5px;
	}

	.modal {
		padding-right: 0 !important;
	}
}

// .modal-dialog {
// 	max-width: 100%;
// 	//  height: auto;
// 	margin: 0;
// }
.modal-dialog {
	max-width: 50%;
	height: 100vh;
	margin: 0;
	@include media-breakpoint-down(md) {
		max-width: 100%;
	}
}

.modal-about {
	.modal-dialog {
		height: auto !important;
		pointer-events: initial;
	}
}

.modal-news {
	.modal-dialog {
		height: auto !important;
		pointer-events: initial;
	}
}

.modal-route {
	.modal-dialog {
		height: auto !important;
		pointer-events: initial;
	}
}

.modal-partner {
	.modal-dialog {
		height: auto !important;
		pointer-events: initial;
	}
}
.label-checkbox__icon {
	padding-right: 16px;
	// display: inline-flex;
	display: inline-table;
	a {
		color: #fff;
	}
	@include media-breakpoint-down(lg) {
		position: relative;
		top: -5px;
		font-size: 16px;
	}
}
.label-checkbox__link {
	border-bottom: 1px solid #fff;
	&:hover {
		border-bottom: 1px solid transparent;
	}
}

.modal-order,
.modal-route,
.modal-partner {
	&.show {
		display: flex !important;
		justify-content: flex-end;
			& .modal-dialog {
				width: 50%;
				overflow-y: auto;
				backdrop-filter: blur(5px);
			}

			@media (max-width: 998px) {
				display: block;

				& .modal-dialog {
					width: 100%;
					overflow-y: auto;
				}
			}
	}

	input[type="checkbox"],
	input[type="radio"] {
		display: none !important;
	}

	.input-checkbox2[type="checkbox"] + label:before {
		content: "\2022";
		cursor: pointer;
		color: transparent;
		margin-right: 16px;
		display: inline-block;
		border: 1px solid #fff;
		border-radius: 2px;
		font-size: 20px;
		line-height: 12px;
		padding: 0 2px 0 2px;
		//margin: -5px 5px 0 0;
		height: 13px;
		width: 13px;
		text-align: center;
		vertical-align: middle;
		transition: color ease 0.3s;
		@include media-breakpoint-down(xs) {
			margin-right: 6px;
		}
	}

	.input-checkbox2[type="checkbox"]:checked + label:before {
		color: #fff;
		border-color: #fff;
		padding-bottom: -15px;
		height: 13px;
		width: 13px;
	}
}
.modal__index {
	z-index: 1060;
	.modal-glass {
		position: absolute;
	}
	.modal-head {
		@include media-breakpoint-down(lg) {
			top: 16px;
		}
	}

	.modal-content {
		@include media-breakpoint-down(lg) {
			padding: 0 0 0 10px;
		}

		@include media-breakpoint-down(sm) {
			padding: 44px 0 0 1px;
		}

		.modal__h2 {
			@include media-breakpoint-down(lg) {
				font-size: 28px;
				line-height: 30px;
			}
		}
	}

	.form-check {
		@include my(15px, 0);
	}
	.form {
		width: 80%;

		@include media-breakpoint-down(lg) {
			padding-top: 16px;
		}

		@include media-breakpoint-down(xl) {
			width: 90%;
		}
		@include media-breakpoint-down(xs) {
			width: 100%;
		}
	}
	.form__item {
		@include media-breakpoint-down(lg) {
			padding-bottom: 12px;
		}

		textarea {
			max-height: 150px;
			resize: vertical;
		}
	}
	.form__item_flex {
		display: flex;
		@include media-breakpoint-down(lg) {
			flex-direction: column;
		}
	}
	.form__item_check {
		display: flex;
	}
	.form__field_phone {
		width: 38%;
		@include py(16px);
		@include px(20px);
		border-bottom: none !important;
		background: rgba(255, 255, 255, 0.3) !important;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
		margin-right: 20px;
		@include media-breakpoint-down(lg) {
			width: 100%;
		}
	}
	.btn {
		position: relative;
		max-width: 216px;
		width: 100%;
		@include media-breakpoint-down(lg) {
			max-width: 100%;
			width: 100%;
			margin-top: 24px;
		}
	}
	.input-checkbox {
		width: auto;
		height: 30px;
		display: flex;
		align-items: center;
		text-align: center;
	}
	.label-checkbox {
		@include px(0px, 0);
		width: 100%;
		color: $color__white;
		font-weight: 300;
		@include media-breakpoint-down(lg) {
			width: 100%;
			display: flex;
		}
	}
}

.modal-backdrop.show {
	opacity: 1 !important;
	backdrop-filter: blur(5px) !important;
	background-color: transparent !important;
}

.modal__index,
.modal-news {
	// &.show {
	// 	display: flex !important;
	// 	justify-content: flex-end;

		& .modal-dialog {
			width: 100%;
            max-width: 100%;
		// }
	}
}

.fade {
    -webkit-transition: opacity 0s linear;
    transition: opacity 0s linear;
}
