.promo {

   @include media-breakpoint-between(lg,xl) {
        @include py(194px,200px);
    }

    @include media-breakpoint-down(md) {
        @include my(160px,185px);
    }

    @include media-breakpoint-down(sm) {
        @include my(70px,25px);
    }

    @include media-breakpoint-down(xs) {
        @include my(70px,32px);
    }

    &__content {
        @include media-breakpoint-between(lg,xl) {
            display: flex;
            justify-content: space-between;
        }
        @include media-breakpoint-down(md) {
            display: flex;
            justify-content: space-between;
        }
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }
    h1 {
        &::after {
            content: "";
            display: block;
            height: 6px;
            width: 100%;
            transition: all 0.5s;
            background: linear-gradient(90deg,#7fb493,rgba(80,190,160,0));
            animation: myanim 3.9s infinite;
        }
    }

    .text-template {
        @include media-breakpoint-down(sm) {
            margin-top: 16px;
        }
    }
}

.promo__col {
    &_tab {
        @include media-breakpoint-down(xl) {
            @include fw(100%/12*7.5);
        }
        @include media-breakpoint-down(lg) {
            @include fw(100%/12*7);
        }
        @include media-breakpoint-down(md) {
            @include fw(100%/12*7);
        }
        @include media-breakpoint-down(sm) {
            @include fw(100%/8*8);
        }
    }
    &_nav {
        @include media-breakpoint-between(lg,xl) {
            @include fw(100%/12*2);
        }
        @include media-breakpoint-down(md) {
            @include fw(100%/12*3);
        }
        @include media-breakpoint-down(sm) {
            @include fw(100%/8*8);
        }
    }
}
.promo__content_nav {
    @include media-breakpoint-between(md,xl) {
        position: absolute;
        right: 0;
        top: -29px;
        padding-top: 122px;
        padding-right: 30px;
    }
    // @include media-breakpoint-down(md) {
    //     @include my(80px,0);
    // }
    // @include media-breakpoint-down(sm) {
    //     @include my(80px,0);
    // }
    // @include media-breakpoint-down(xs) {
    //    // display: none;
    // }
    .swiper-wrapper{
        transform: translate3d(0px, 0, 0) !important;
    }
    .swiper-slide {
        height: auto;
    }
}
.promo__subtitle {
    color: $color__white;
    @include title(24px,33px,400);
    @include media-breakpoint-down(xl) {
        @include my(32px,0);
        // width: 50%;
    }
    @include media-breakpoint-between(md,lg) {
        @include my(32px,0);
        @include title(18px,24px,400);
        width: 100%;
        margin-bottom: 32px;
    }

    @include media-breakpoint-down(sm) {
        @include title(18px,25px,normal);
        @include my(32px, 0);
    }

    @include media-breakpoint-down(xs) {
        @include title(18px,25px,normal);
        width: 100%;
    }
}
.promo__descr {
    @include media-breakpoint-down(xl) {
        // width: 55%;
    }
    @include media-breakpoint-between(md,lg) {
        width: 100%;
    }
    @include media-breakpoint-down(md) {
        @include my(0,32px);
    }
    @include media-breakpoint-down(sm) {
       width: 100%;
    }
}
.promo__btn {
    display: flex;
    justify-content: end;
    cursor: pointer;
    @include fs-lh(14px,19px);
    color: $color__green-text;
    font-weight: 300;
    max-width: 50%;
    text-transform: uppercase;
    height: 63px;
    &_active {
        color: $color__white;
    }

    @include media-breakpoint-between(md,xl) {
       @include py(0, 32px);
       margin-left: auto;
    }
    @include media-breakpoint-down(sm) {
        display: flex;
        justify-content: end;
        @include py(0, 32px);
    }
    @include media-breakpoint-down(xs) {
        display: none;
    }
}

.promo-prev {
    position: absolute;
    top: 65px;
    right: 60px;
    width: 12px;
    height: 10px;
    z-index: 10;
    // @include media-breakpoint-down(xl) {
    //     top: 100px;
    // }
    // @include media-breakpoint-down(md) {
    //     top: 10px;
    // }
    @include media-breakpoint-down(sm) {
        transform: rotate(90deg);
        right: 0;
        bottom: 3px;   
        top: auto;
        z-index: 10;
    }
}

.promo-next {
    position: absolute;
    right: 60px;
    width: 12px;
    height: 10px;
    // @include media-breakpoint-down(xl) {
    //     z-index: 10;
    //     bottom: 45px;
    // }
    // @include media-breakpoint-down(md) {
    //     //bottom: 155px;
    //     bottom: 160px;
    // }
    @include media-breakpoint-down(sm) {
        transform: rotate(90deg);
        right: auto;
        left: 22px;
        bottom: 3px;   
        top: auto;
    }
}

.promo__content_mobile {
    @include media-breakpoint-down(md) {
        display: block;
        @include my(32px,0);
        .swiper-wrapper {
            display: flex;
            justify-content: center;
            margin-left: 10px;
            flex-direction: row!important;
        }
        .swiper-slide {
            width: auto;
        }
    }
    @include media-breakpoint-down(md) {
        .swiper-slide-thumb-active {
            color: $color__green-text;
            position: relative;
            &::before {
                content:'';
                position: absolute;
                top: -3px;
                left: 1px;
                width: 7px;
                height: 2px;
                background: $color__green-text;
            }
        }
    }
}

.promo__number {
    display: none;
    @include media-breakpoint-down(md) {
       display: block;
       color: $color__white;
       @include fs-lh(14px,16px);
       @include mx(0,24px);
    }
}

.promo__mobile {
    display: none;
    @include media-breakpoint-down(sm)  {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }
}

.promo__content_mobile {
    @include media-breakpoint-down(md)  {
        @include py(35px,0);
        margin-top: 0;
    }
}

.promo__content_nav {
    .swiper-button-disabled {
        opacity: 0.2;
    }
}

.promo__col_nav {
    .swiper-button-disabled {
        opacity: 0.4;
    }
}

@keyframes myanim {
    from {
        background:#7FB493;
        width: 30%;
    }
    to {
        background:rgba(80,190,160,0);
        width: 100%;
    }
}