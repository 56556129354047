
@import '~bootstrap/scss/bootstrap-reboot.scss';
@import '~bootstrap/scss/modal';
@import '~bootstrap/scss/images';
@import '~bootstrap/scss/tables';
@import '~bootstrap/scss/dropdown';
@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/utilities/flex';
@import '~bootstrap/scss/utilities/display';
@import '~bootstrap/scss/utilities/spacing';

/* base */
@import 'mixins';
@import 'variables';
@import 'base/extends';
@import 'base/common';
@import 'base/btn';
@import 'base/fonts';

/* layout */
@import 'layout/header';
@import 'layout/footer';
@import 'layout/modals';
@import 'layout/preloader';
@import 'layout/tabs';
@import 'layout/aos';
@import 'layout/404';
@import 'layout/burger';

/* blocks */
@import './blocks/promo';
@import './blocks/nav';
@import './blocks/text';
@import './blocks/about';
@import './blocks/card';
@import './blocks/product';
@import './blocks/video';
@import './blocks/project';
@import './blocks/news';
@import './blocks/clients';
@import './blocks/location';
@import './blocks/piramide';
@import './blocks/services';
@import './blocks/swiper';
@import './blocks/info';
@import './blocks/task';
@import './blocks/materials';
@import './blocks/page-about';
@import './blocks/page-news';
@import './blocks/article';
@import './blocks/form';
@import './blocks/_popup';
@import './blocks/popup-partners';