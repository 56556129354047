.page-news {
    background: $color__white;
    height: 100%;
    @include media-breakpoint-between(lg,xl) {
        display: flex;
    }
    .content {
        @include media-breakpoint-between(lg,xl) {
            @include fw(100%/12*5.5);
        }
    }
    .post {
        @include media-breakpoint-between(lg,xl) {
            @include fw(100%/12*6.5);
        }
    }
    .sticky-bg {
        @include media-breakpoint-down(md) {
            height: 500px;
            .img-fluid {
                width: 100%;
                object-fit: cover;
                height: 100%;
            }
        }
        @include media-breakpoint-down(sm) {
            height: 300px;
            .img-fluid {
                width: 100%;
                object-fit: cover;
                height: 100%;
            }
        }
    }
}

.page-news-content {
    @include media-breakpoint-between(sm,xl) {
        @include py(32px,54px);
        @include px(54px);
    }
    @include media-breakpoint-down(sm) {
        @include py(32px,54px);
        @include px(12px);
    }
}