.services {
    background: linear-gradient(108.45deg, #167037 11.98%, #282D3C 95.31%);
    @include media-breakpoint-between(lg,xl) {
        @include py(80px,92px);
    }
    @include media-breakpoint-down(md) {
        @include py(60px);
    }
    @include media-breakpoint-down(sm) {
        @include py(32px);
    }
    &__h2  {
        color: $color__white;
        @include my(0,32px);
        &_another {
           display: none;
        }
        @include media-breakpoint-down(sm) {
            @include my(0,24px);
            display: none;
            &_another {
                display: block;
            }
        }
    }
    &__text {
        color: $color__white;
        font-weight: 300;
    }
    &__text_min {
        color: $color__white;
        @include py(4px,0);
        @include title(14px,19px,400);
    }
    &__link {
        cursor: pointer;
        display: inline-block;
        color: $color__white;
        border-bottom: 1px solid $color__white;
        @include py(20px,0);
        @include title(18px,25px,400);
        &:hover {
            border-bottom: 1px solid transparent;
        }
    }
    &__info {
        @include media-breakpoint-down(md) {
            @include py(0,0px);
        }
        @include media-breakpoint-down(sm) {
            @include py(0,0px);
        }
    }
    &__text_dn {
        @include media-breakpoint-down(md) {
           display: none;
        }
    }
}

.services__card {
    display: block;
    border: 1px solid $color__white;
    min-height: 228px;
    cursor: pointer;
    @include media-breakpoint-down(xs) {
        min-height: 218px;
    }
    &_content  {
        display: flex;
        flex-direction: column;
        position: relative;
        height: 100%;
        @include media-breakpoint-between(md,xl) {
            @include py(24px);
            @include px(24px);
        }
        @include media-breakpoint-down(sm) {
            @include py(24px);
            @include px(24px);
        }
        @include media-breakpoint-down(xs) {
            @include py(16px);
            @include px(16px);
        }
    }
    &_title {
        color: $color__white;
        @include media-breakpoint-between(md,xl) {
            @include title(20px,27px,300);
            word-break: break-word;
        }
        @include media-breakpoint-down(lg) {
            @include my(0, 30px);
        }
    }
    &_bottom  {
        @include media-breakpoint-between(sm,xl) {
            display: flex;
            justify-content: space-between;
            //height: 165px;
            height: 100%;
            align-items: end;
        }
        @include media-breakpoint-down(xs) {
            justify-content: space-between;
            display: flex;
            //justify-content: end;
            height: 130px;
            align-items: end;
        }
    }
    &_descr  {
        display: grid;
        color: $color__white;
        @include title(16px,22px,400);
        .img-fluid {
            @include py(12px,0);
        }
        @include media-breakpoint-down(xs) {
            @include title(14px,19px,300);
        }
    }
    &_preview {
        width: 64px;
        height: 64px;
        .icon-fluid {
            width: 100%;
            height: 100%;
        }
        @include media-breakpoint-down(sm) {
            width: 75px;
        }
        @include media-breakpoint-down(xs) {
            position: relative;
            top: 0px;
            width: 75px;
        }
    }
    &_arrow {
        width: 41px;
        height: 10px;
        .icon-fluid {
            width: 100%;
            height: 100%;
            transition: all 0.5s;
        }
    }
    &_logo {
        display: flex;
        justify-content: center;
    }
    &_icon {
        cursor: pointer;
        background: $color__white;
        border-radius: 60px;
        @include wh(89px,89px);
        transition: all 0.5s;
        fill:#282D3C;
        .icon-fluid {
            position: relative;
            top: 20px;
            @include wh(48px,43px);
        }
        &:hover {
            background: $color__green-tab;
            fill: #fff;
        }
    }
    &:hover  .services__card_arrow .icon-fluid{
        transform: translateX(20px);
        transition-timing-function: ease;
    }
}

.services__wrapper {
    @include media-breakpoint-between(lg,xl) {
        display: flex;
        justify-content: space-between;
        gap: 16px;
        //row-gap: 30px;
        //column-gap: 16px;
        flex-wrap: wrap;
    }
    @include media-breakpoint-down(md) {
        display: flex;
        flex-wrap: wrap;
        //justify-content: space-between;
        row-gap: 16px;
        column-gap: 16px;
    }
}

.services__col {
    &_lg {
        @include media-breakpoint-down(xl) {
            @include fw(100%/12*8.8);
            .services__text {
                width: 50%;
            }
        }
        @include media-breakpoint-down(lg) {
            @include fw(100%/12*8.6);
            .services__text {
                width: 90%;
            }
        }
        @include media-breakpoint-down(md) {
            @include fw(100%/12*12);
        }
        @include media-breakpoint-down(sm) {
            @include fw(100%/8*8);
            .services__text {
                width: 100%;
            }
        }
    }
    &_m {
        @include media-breakpoint-down(xl) {
            @include fw(100%/12*5.6);
            .services__text {
                width: 75%;
            }
        }
        @include media-breakpoint-down(lg) {
            @include fw(100%/12*5.2);
            .services__text {
                width: 75%;
            }
        }
        @include media-breakpoint-down(md) {
            @include fw(100%/12*5.4);
            display: none;
        }
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }
    &_dn {
        display: none;
        @include media-breakpoint-down(md) {
            @include fw(100%/8*8);
            display: block;
            @include py(0,16px);
        }
        @include media-breakpoint-down(sm) {
            @include py(0,8px);
        }
    }
    &_sm {
        @include media-breakpoint-down(xl) {
            @include fw(100%/12*3);
            height: 250px;
        }
        @include media-breakpoint-down(lg) {
            @include fw(100%/12*3);
            height: auto;
        }
        @include media-breakpoint-down(md) {
            height: auto;
            @include fw(100%/8*3.8);
        }
        @include media-breakpoint-down(sm) {
            @include fw(100%/8*8);
        }
    }
    &_xs {
        @include media-breakpoint-down(xl) {
            @include fw(100%/12*2.4);
        }
        @include media-breakpoint-down(lg) {
            @include fw(100%/12*2);
        }
        
        @include media-breakpoint-down(md) {
            display: none;
        }
        @include media-breakpoint-down(sm) {
           display: none;
        }
    }
}

.services__card_bn {
    border: none;
}
.services__card_center {
    text-align: center;
    @include py(35px);
    @include media-breakpoint-down(sm) {
        @include py(24px, 0);
    }
}