.nav {
    @include media-breakpoint-down(lg) {
        display: none;
    }
    &__list {
        @include media-breakpoint-between(md,xl) {
            display: flex;
        }
    }
    &__item {
        @extend %list-default;
       
        @include media-breakpoint-between(md,xl) {
            @include px(0,32px);
        }

    }

    &__link {
        display: block;
        @include  fs-lh(14px, 19px);
        font-weight: 300;
        color: $color__white;
        transition: all .3s ease;
        &:hover {
            opacity: 0.7;
        }
    }
    &__title {
        display: block;
        color: $color__white;
        font-family: $font-family_title;
        font-weight: 700;
        @include my(0,16px);

    }
}
.nav__link_pt {
    @include media-breakpoint-between(sm,xl) {
        @include py(0,13px);
    }
}
#about {
    transition: all .3s ease-in-out;
}
#services {
    transition: all .3s ease-in-out;
}
#product {
    transition: all .3s ease-in-out;
}
#project {
    transition: all .3s ease-in-out;
}
#clients {
    transition: all .3s ease-in-out;
}
#contacts {
    transition: all .3s ease-in-out;
}