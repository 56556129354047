.tab-nav {
    padding: 0;
    position: relative;
    display: inline-block;
 
    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 7px;
        height: 75%;
        width: 2px;
        background: $color__green-btn;
        @include media-breakpoint-between(xs,sm) {
            display: none;
        }
    }
    li {    
        position: relative;
        display: table;
        &::before {
            content: '';
            position: absolute;
            left: -4px;
            top: 6px;
            width: 10px;
            height: 10px;
            border: 1px solid $color__green-btn;
            border-radius: 21px;
            background: $color__white;
        }
    }
    &__item {
        cursor: pointer;
        list-style-type: none;
        font-weight: 400;
        @include fs-lh(16px,22px);

        @include media-breakpoint-between(md,xl) {
            @include py(0,38px);
            @include px(35px,0);
        }
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }
}

.tab-news {
    &__nav {
        padding: 0;
        @include media-breakpoint-between(lg,xl) {
            display: flex;
            @include my(0,45px);
        }
        @include media-breakpoint-down(md) {
            display: flex;
            @include my(0,40px);
        }
        @include media-breakpoint-down(sm) {
            display: flex;
            flex-wrap: wrap;
            @include my(0,16px);
        }
    }
    &__item {
        cursor: pointer;
        list-style-type: none;
        @include fs-lh(16px,22px);
        @include media-breakpoint-between(sm,xl) {
           @include px(0,43px);
        }
        @include media-breakpoint-down(sm) {
            @include fs-lh(16px,22px);
        }
        @include media-breakpoint-down(xs) {
            @include px(0,24px);
            @include py(0,16px);
        }
    }
}
.swiper-wrapper {
    height: fit-content;
}
.tab-news__content {
   // display: flex;
   // justify-content: space-between;
   overflow: hidden;
}

.tab-clients {
    @include media-breakpoint-down(sm) {
        display: none;
    }
    &__nav {
        padding: 0;
        @include media-breakpoint-between(sm,xl) {
            display: flex;
            flex-wrap: wrap;
        }
    }
    &__item {
        cursor: pointer;
        list-style-type: none;
        text-transform: uppercase;
        font-family: $font-family_title;
        -webkit-text-stroke: 1px $color__black;
        -webkit-text-fill-color: $color__white;
        @include media-breakpoint-between(lg,xl) {
           @include px(0,16px);
           @include py(0,12px);
           @include fs-lh(40px,47px);
        }
        @include media-breakpoint-down(md) {
            @include px(0,16px);
            @include py(0,12px);
            @include fs-lh(32px,38px);

        }
        @include media-breakpoint-down(sm) {
            @include py(0,8px);
            @include fs-lh(28px,32px);
        }
    }
    &__item_active  {
        -webkit-text-stroke: 1px $color__green-tab;
        -webkit-text-fill-color: $color__green-tab;
        font-weight: 700;
    }
    &__content {
        @include media-breakpoint-between(lg,xl) {
            @include py(40px,0);
        }
        @include media-breakpoint-down(md) {
            @include py(20px,0);
        }
        @include media-breakpoint-down(sm) {
            @include py(32px,0);
        }
    }
    &__link {
        transition: ll 0.5s;
        &:hover {
            -webkit-text-fill-color: #167038;
            font-weight: 700;
            -webkit-text-stroke: #fff;
        }
    }
}


.tab-location {
    &__nav {
        padding: 0;
        display: flex;
        @include media-breakpoint-between(lg,xl) {
            @include py(0,140px);
        }
        @include media-breakpoint-between(xs,md) {
            @include py(0,60px);
        }
    }
    &__item {
        cursor: pointer;
        list-style-type: none;
        color: $color__black;
        @include media-breakpoint-between(md,xl) {
           @include px(0,40px);
        }
        @include media-breakpoint-down(sm) {
            @include px(0,0px);
        }
    }
    &__item_active {
        color: $color__white;
    }
    &__content {
        @include media-breakpoint-between(lg,xl) {
            //@include py(140px,0);
        }
    }
    .pl {
        @include media-breakpoint-down(sm) {
            @include px(0,39px);
        }
    }
}

.tabs-content-js {
    transition: all 2.5s!important;
}
.active {
    opacity: 1;
    transition: all 2.5s!important;
}

.active__colors {
    opacity: 1;
    transition: all 1s;
    //-webkit-text-stroke: 1px #167038;
    -webkit-text-fill-color: #167038;
    font-weight: 700;
    -webkit-text-stroke: #fff;
}
.active__colors_white {
    opacity: 1;
    transition: all 1s;
    color: $color__white;
}
.active__colors_black {
    opacity: 1;
    transition: all 1s;
    color: $color__black;
}
.active__colors_green {
    opacity: 1;
    transition: all 1s;
    color: $color__green-btn;
}

.project__tab-lg-ative {
    color: $color__green-btn;
    opacity: 1;
    transition: all 1s;
    position: relative;
}
 .project__tab-lg-ative li{    
    position: relative;
    &::before {
        background:  $color__green-btn;
    }
}

.tab-pane {
    opacity: 0;
    height: 0;
    overflow: hidden;
    transition: all 2s ease;

    &.active {
        opacity: 1;
        height: auto;
        overflow: visible;
        transition: all 0.5s ease;
    }
}