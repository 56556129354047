.project {
    @include media-breakpoint-between(lg,xl) {
        @include py(80px,0);
    }
    @include media-breakpoint-down(md) {
        @include py(60px, 40px);
    }
    @include media-breakpoint-down(sm) {
        @include py(32px);
    }
    .text-template {
      
       @include media-breakpoint-between(lg,xl) {
            @include my(32px,48px);
        }
        @include media-breakpoint-down(md) {
            @include my(32px);
            width: 55%;
        }
        @include media-breakpoint-down(sm) {
            @include my(24px);
            width: 100%;
        }
    }
    .card-news__descr_before {
        &::before {
            content: '';
            position: absolute;
            left: -12px;
            bottom: 6px;
            background: $color__white;
            border-radius: 21px;
            width: 4px;
            height: 4px;
            @include media-breakpoint-between(xs,sm) {
                bottom: 8px;
            }
        }
    }
    .h4 {
        position: relative;
        z-index: 10;
        color:  $color__white;
        @include media-breakpoint-between(md,xl) {
            @include py(0,16px);
        }
        @include media-breakpoint-between(xs,sm) {
            @include fs-lh(24px,28px);
        }
    }
    .card-news__descr {
        @include media-breakpoint-between(xs,sm) {
            @include fs-lh(16px,21px);
            @include py(16px,0);
        }
    }
    .card-news__content {
        @include media-breakpoint-down(lg) {
            @include px(12px);
        }
    }
    .tab-nav {
        @include media-breakpoint-between(s,md) {
            display: none;
        }
    }
    .card-news__content_bottom {
        width: 80%;
    }
    .card-news__content_bottom {
        .card-news__descr {
            @include fs-lh(14px,17px);
            @include media-breakpoint-between(xs,sm) {
                @include fs-lh(16px,21px);
                @include py(16px,0);
            }
        }
    }
}

.project__wrapper {
    position: relative;
    padding-bottom: 50px;
    @include media-breakpoint-between(lg,xl) {
        display: flex;
        justify-content: space-between;
    }
}
.project__col {
    &_left {
        @include media-breakpoint-between(lg,xl) {
            @include fw(100%/12*4.5);
        }
        @include media-breakpoint-down(md) {
            .btn {
                display: none;
            }
        }
    }
    &_right {
        @include media-breakpoint-between(lg,xl) {
            @include fw(100%/12*6.2);
            position: relative;
            margin-top: 80px;
        }
        @include media-breakpoint-down(md) {
            display: none;
        }
    }
    &_mobile {
        @include media-breakpoint-between(lg,xl) {
            display: none;
        }
        @include media-breakpoint-down(md) {
           display: block;
        }
    }
}

.project__position {
    position: sticky;
    top: 80px;
    height: fit-content;
    width: 100%;
    @include my(0,150px);
    @include media-breakpoint-down(md) {
        top: 0;
        @include my(0);
        position: relative;
    }
    @include media-breakpoint-down(sm) {
        @include my(0,0);
    }
}
.project__tab {
    &_nav {
        display: flex;
        align-items: center;
        @include fs-lh(16px,22px);
        color: $color__black;
        @include my(0,32px);
        &-ative  {
            color: $color__green-btn;
        }
    }
    &_content {
        @include media-breakpoint-down(sm) {
            @include my(32px,0);
        }
    }
    .card-news__content {
        left: 0;
        @include media-breakpoint-down(sm) {
            @include py(24px,16px);
            @include px(16px);
        }   
    }
    .card-news__descr {
        @include media-breakpoint-down(sm) {
            @include fs-lh(16px,22px);
            @include py(16px,0);
        } 
    }
    .card-news__descr_before:before {
        bottom: 8px;
    }
    .h4 {
        @include media-breakpoint-down(sm) {
            color: $color__white;
            @include fs-lh(24px,28px);
        } 
    }
}

.project__card {
    @include media-breakpoint-down(md) {
        @include my(0,32px);
        height: 300px;
    } 
    @include media-breakpoint-down(sm) {
        @include my(0,12px);
    } 
    @include media-breakpoint-down(sm) {
        max-height: 284px;
    } 
    .card-news__bg {
        @include media-breakpoint-down(sm) {
            max-width: 100%;
            height: 100%;
            .img-fluid {
                object-fit: cover;
                height: 100%;
            }
        } 
    }
    .card-news__bg {
        @include media-breakpoint-down(md) {
            max-width: 100%;
            height: 100%;
            .img-fluid {
                max-width: 100%;
                object-fit: cover;
                height: 100%;
            }
        } 
        @include media-breakpoint-down(sm) {
            max-width: 100%;
            height: 100%;
            .img-fluid {
                max-width: 100%;
                object-fit: cover;
                height: 100%;
            }
        } 
    }
}

.project__tab_nav-ative{
    color: $color__green-btn;
    opacity: 1;
    transition: all 1s;
    position: relative;
    .icon-fluid {
        width: 40px;
        height: 40px;
        margin-right: 20px;
        transition: all 1s;
    }
    &::before {
        content: '-'!important;
        transition: all 1s;
        position: absolute;
        left: 0;
        color: $color__green-btn;
        text-align: center;
        width: 40px;
    }
}


.product__tab_nav_hov {
    opacity: .6!important;
    transition-duration: .4s;
    &:hover {
        opacity: 1!important;
    }
}
.product__tab{
    .open {
        opacity: 1!important;
    }
}
.project__tab_nav {
    position: relative;
    .icon-fluid {
        width: 40px;
        height: 40px;
        margin-right: 20px;
    }

    &::before {
        content: '+';
        position: absolute;
        left: 0;
        color: $color__green-btn;
        text-align: center;
        width: 40px;
    }
}

.project__tab-lg-ative {
    &::before {
        content: '';
        position: absolute;
        left: -4px;
        top: 6px;
        width: 10px;
        height: 10px;
        border: 1px solid $color__green-btn;
        border-radius: 21px;
        background:  $color__green-btn!important;
    }
}



// .product__tab_firstbox{
//     height: 70px;
//     width: 285px;
//     cursor: pointer;
// }
// .product__tab_firstbox .product__tab_nav_hov_second{
//     display: none;
// }
// .product__tab_firstbox:hover > .product__tab_nav_first{
//     display: none !important;
// }
// .product__tab_firstbox:hover > .product__tab_nav_hov_second{
//     display: block !important;
// }

// .product__tab_secondbox{
//     height: 70px;
//     width: 285px;
//     cursor: pointer;
// }
// .product__tab_secondbox .product__tab_nav_hov_forth {
//     display: none;
// }
// .product__tab_secondbox:hover > .product__tab_nav_third{
//     display: none !important;
// }
// .product__tab_secondbox:hover > .product__tab_nav_hov_forth {
//     display: block !important;
// }
