[data-aos="custom"] {
	transform: translateY(100px);
	transition-property: transform;
	transition: all 0.5s;
}

[data-aos="custom"].aos-animate {
	transform: translateY(0);
}

[data-aos="piramide"] {
	transform: translateY(100px);
	transition-property: transform;
	transition: all 0.5s;
}

[data-aos="piramide"].aos-animate {
	transform: translateY(0);
}