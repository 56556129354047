.about {
    @include media-breakpoint-between(lg,xl) {
        @include py(80px, 20px);
    }

    @include media-breakpoint-down(md) {
        @include py(60px);
    }

    @include media-breakpoint-down(sm) {
        @include py(32px);
    }

    &__h3 {
        display: inline-block;
        position: relative;
        &:after {
            content: '';
            position: absolute;
            left: 0;
            bottom: -8px;
            background: linear-gradient(108.45deg, #167037 11.98%, #282D3C 95.31%);
            width: 98%;
            height: 6px;
            @include media-breakpoint-down(xs) {
                height: 3px;
            }
        }
        @include media-breakpoint-between(lg,xl) {
           // width: 23%;
            @include py(60px,0);
        }
        @include media-breakpoint-down(md) {
            @include py(40px,0);
        }
        @include media-breakpoint-down(sm) {
            @include py(24px,0);
            @include title (20px,23px,700);
        }
    }
    .text-template {
        margin: 0;
        @include media-breakpoint-down(md) {
            @include my(32px);
        }
        @include media-breakpoint-down(sm) {
            @include my(24px);
            @include fs_lh(18px,24px);
        }
    }

    &__title {
        border-bottom: 1px solid $color__grey;
        @include media-breakpoint-between(lg,xl) {
            @include py(0,24px);
        }
        @include media-breakpoint-down(md) {
            @include py(0,32px);
        }
        @include media-breakpoint-down(sm) {
            @include py(0,16px);
        }
    }

    &__descr {
        @include media-breakpoint-between(lg,xl) {
            @include py(16px,0);
        }
        p {
            font-weight: 400;
        }
        @include media-breakpoint-down(md) {
            @include py(8px,0);
        }

        @include media-breakpoint-down(sm) {
            @include py(8px,0);
        }
    }
}
.about__wrapper {
    @include media-breakpoint-between(lg,xl) {
        display: flex;
        justify-content: space-between;
        @include py(32px,0);
    }
}
.about__col {
    &.about__col_left {
        @include media-breakpoint-between(lg,xl) {
            @include fw(100%/12*2);
        }
    }
    &.about__col_right {
        @include media-breakpoint-between(lg,xl) {
            @include fw(100%/12*9);
        }
    }
}