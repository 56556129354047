.product {
    background: $color__blue;
    @include media-breakpoint-between(lg,xl) {
        @include py(80px, 50px)
    }
    @include media-breakpoint-down(md) {
        @include py(60px);
    }
    @include media-breakpoint-down(sm) {
        @include py(32px);
    }
    &__h3 {
        @include media-breakpoint-between(md,xl) {
            @include my(32px,0);
        }
        @include media-breakpoint-down(sm) {
            @include py(24px,0);
            @include  fs-lh(24px,28px);
        }
    }
    .text-template {
        margin: 16px 0;
        font-weight: 400;
        @include media-breakpoint-down(md) {
            @include my(32px);
            width: 55%;
        }
        @include media-breakpoint-down(sm) {
            @include my(24px);
            width: 100%;
        }
    }
    &__link {
        display: inline-block;
        color: $color__black;
        border-bottom: 1px solid  $color__black;
        font-weight: 600;
        &:hover {
            color: $color__black;
            border-bottom: 1px solid transparent;
        }
        @include media-breakpoint-between(md,xl) {
            @include my(0,32px);
            @include  fs-lh(16px,22px);
        }
        @include media-breakpoint-down(sm) {
            @include  fs-lh(16px,22px);
            @include my(0,24px);
        }
    }
    .btn {
        max-width: 280px;
    }
}

.product__descr {
    .text-template {
        @include media-breakpoint-down(md) {
            width: 100%;
            @include my(16px);
        }
        @include media-breakpoint-down(sm) {
            @include my(16px);
        }
    }
}
.product__wrapper {
    @include media-breakpoint-between(lg,xl) {
        @include my(16px);
        display: flex;
        justify-content: space-between;
    }
}
.product__col {
    &_left {
        @include media-breakpoint-between(lg,xl) {
            @include fw(100%/12*4);
        }
        @include media-breakpoint-down(md) {
            @include fw(100%/12*12);
        }
    }
    &_right {
        @include media-breakpoint-between(lg,xl) {
            @include fw(100%/12*6);
        }
        @include media-breakpoint-down(md) {
            @include fw(100%/12*12);
        }
    }
}

.product__tab {
    @include media-breakpoint-between(lg,xl) {
        @include py(80px)
    }
    @include media-breakpoint-down(md) {
       display: flex;
       align-items: center;
    }
    &_nav {
        cursor: pointer;
        @include media-breakpoint-between(lg,xl) {
            @include py(0, 32px);
            display: inline-block;
        }
        @include media-breakpoint-down(md) {
            @include px(0, 60px);
            @include my(0, 34px);
            width: 200px;
            height: 30px;
        }
        @include media-breakpoint-down(sm) {
            @include px(0);
            @include mx(0, 38px);
           // width: 100%;
            height:auto;
        }
        .img-fluid {
            @include media-breakpoint-down(sm) {
                width: 100%;
                object-fit: contain;
            }
        }
    }
}