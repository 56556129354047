h1 {
    font-family: $font-family_title;
    @include media-breakpoint-between(lg,xl) {
       @include title (60px,70px,700);
       color: $color__white;
    }
    @include media-breakpoint-down(md) {
        @include title (40px,47px,700);
        color: $color__white;
    }
    @include media-breakpoint-down(sm) {
        @include title (34px,40px,700);
        color: $color__white;
        word-break: break-word;
        width: 90%;
    }
}

.h2 {
    font-family: $font-family_title;
    color: $color__title;
    @include media-breakpoint-between(lg,xl) {
       @include title(60px,70px,700);
    }
    @include media-breakpoint-down(md) {
        @include title(40px,47px,700);
    }

    @include media-breakpoint-down(sm) {
        @include title (32px,38px,700);
    }
}

.h3 {
    font-family: $font-family_title;
    color: $color__title;
    @include media-breakpoint-between(lg,xl) {
       @include title (32px,38px,700);
    }

    @include media-breakpoint-down(md) {
        @include title (24px,38px,700);
    }

    @include media-breakpoint-down(sm) {
        //@include title (34px,40px,700);
        @include title (20px,23px,700);
    }
}

.h4 {
    font-family: $font-family_title;
    color: $color__title;
    @include media-breakpoint-between(ld,xl) {
       @include title (24px,33px,600);
    }

    @include media-breakpoint-down(md) {
        @include title (20px,27px,700);
    }

    @include media-breakpoint-down(sm) {
        @include title (18px,25px,700);
    }
}

.text-template {
    font-weight: 300;
    @include media-breakpoint-between(lg,xl) {
        @include my(16px,32px);
        @include fs-lh(18px,25px);
    }

    @include media-breakpoint-down(md) {
        @include fs-lh(16px,22px);
    }

    @include media-breakpoint-down(sm) {
        @include my(8px,24px);
        @include fs-lh(18px,22px);
    }
    &__light {
        color: $color__white;
    }
    &__grey {
        color: #68676C;
    }
}

.title__active {
    display: flex;

    .title__round {
        margin-top: 5px;
        margin-right: 22px;
        width: 20px;
        height: 20px;
        background: $color__green-tab;
        border-radius: 2px;
    }
}

.color-green {
    color: $color__green-btn!important;
}

.border-green {
    border: 1px solid  $color__green-btn!important;
}