.news {
    @include media-breakpoint-between(lg,xl) {
        background: $color__blue;
        @include py(80px,127px);

        .text-template {
            margin-bottom: 45px;
            width: 40%;
            @include media-breakpoint-down(lg) {
                width: 60%;
            }
        }
    }
    @include media-breakpoint-down(md) {
        @include py(20px,0);
        .text-template {
            @include my(32px,40px);
            width: 50%;
        }
    }   
    @include media-breakpoint-down(sm) {
        @include py(5px,0);
        .text-template {
            @include my(24px);
            width: 100%;
        }
    }
    .h2 {
        //font-weight: normal;
        @include media-breakpoint-down(md) {
            @include fs-lh(60px,82px);
        }
        @include media-breakpoint-down(sm) {
            @include fs-lh(32px,37px);
        }
    }
    .h4 {
        position: relative;
        z-index: 10;
        color: $color__white;
        @include py(24px,0);
        @include media-breakpoint-between(md,xl) {
            //width: 90%;
        }
        @include media-breakpoint-down(md) {
            @include fs-lh(24px,28px);
        }
    }
    .card-news__descr {
        cursor: pointer;
        @include py(8px,0);
        @include fs-lh(14px,19px);
    }
    .card-news__h2 {
        font-family: $font-family_default;
    }

    .card-news__text {
        color: #fff;
        @include media-breakpoint-down(sm) {
            margin-top: 30px;
        }
        @include media-breakpoint-down(xs) {
            margin-top: 20px;
            font-weight: 400;
        }
    }
    .card-news__bg {
        @include media-breakpoint-down(md) {
            max-width: 100%!important;
            height: 384px;
            .img-fluid {
                max-width: 100%;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        @include media-breakpoint-down(xs) {
            height: 384px;
            .img-fluid {
                width: 100%;
                height: 100%;
            }
        }
    }
    @include media-breakpoint-down(xs) {
        .swiper-pagination-custom {
            display: none;
        }
        .swiper-nav__arrow-prev {
            display: none;
        }
        .swiper-nav__arrow-next {
            display: none;
        }
    }
    .swiper-auto-w  {
        .swiper-slide {
            width: auto!important;
        }
        @include media-breakpoint-down(md) {
            .swiper-slide {
               width: 384px!important;
            }
        }
        @include media-breakpoint-down(xs) {
            .swiper-slide {
                width: 100%!important;
            }
        }
        
    }
}

.news__pagination {
    display: flex;
    justify-content: center;
    @include media-breakpoint-between(sm,xl) {
       @include my(40px,0);
    }
    @include media-breakpoint-down(xs) {
        @include my(24px,0);
    }
    .swiper-wrapper {
        width: 261px;
        display: flex;
        justify-content: space-between;
    }
   
        .swiper-slide-thumb-active {
            width: 26px!important;
            background: $color__green-tab!important;
        }
    
    .news__number {
        @include wh(5px,5px);
        background: rgba(22, 112, 56, 0.5);
    }
    .news__number_tb {
        display: none;
        @include media-breakpoint-down(md) {
            display: none;
        }
    }
}
.news__pagination {
    .news__swiper-wrapper-w {
        width: 50px!important;
        margin: 0 auto;
        margin-top: 30px;
    }
}

html {
    scroll-behavior: smooth;
}