.btn {
    //display: flex;
    display: block;
    text-align: center;
    align-items: center;

    padding: 10px 25px;
    @include fs-lh(18px,25px);
    font-weight: 400;
    @extend %btn-default;
    transition: all .3s ease;
    &.btn-white {
        border: 1px solid $color__white;
        color: $color__white;
        &:hover {
            background: $color__white;
            border: 1px solid  $color__white;
            color: $color__green-btn;
        }
    }
    &.btn-green {
        border: 1px solid $color__green-btn;
        color: $color__green-btn;
        &:hover {
            border: 1px solid $color__green-btn;
            background: $color__green-btn;
            color: $color__white;
        }
    }
    @include media-breakpoint-down(sm) {
        @include fs-lh(16px,22px);
    }
}