.page-error {
    @include media-breakpoint-between(md,xl) {
        @include py(124px,230px);
    }
    @include media-breakpoint-down(md) {
        @include py(120px,180px);
    }
    @include media-breakpoint-down(sm) {
        @include py(60px);
    }
    @include media-breakpoint-down(xs) {
        @include py(60px);
    }
    &__wrapper {
        @include media-breakpoint-down(xs) {
           width: 86%;
        }
    }
    .h1 {
        color: $color__black;
        @include media-breakpoint-between(md,xl) {
            @include py(16px,32px);
        }
        @include media-breakpoint-down(sm) {
            @include py(16px,32px);
        }
        @include media-breakpoint-down(xs) {
            @include py(8px,24px);
        }
    }
    .btn {
        @include media-breakpoint-between(md,xl) {
            @include my(32px,0);
            max-width: 241px;
            width: 100%;
        }
        @include media-breakpoint-down(sm) {
            @include my(32px,0);
        }
        @include media-breakpoint-down(xs) {
            @include my(16px,0);
        }
    }
    &__desc {
        color: $color__light_grey;
        @include media-breakpoint-between(md,xl) {
           max-width: 500px;
           width: 100%;
        }
        @include media-breakpoint-down(xs) {
            word-break: break-word;
        }
    }
}