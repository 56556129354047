.popup-partners {
  padding: 113px 54px 54px 54px;
  @include media-breakpoint-down(sm) {
    padding: 90px 12px;
  }
}
.popup-partners__close {
  right: 62px;
  @include media-breakpoint-down(sm) {
    right: 20px;
  }
}
.popup-partners__header {
  display: flex;
  justify-content: space-between;
  padding-right: 0;
}
.popup-partners__logo{
  width: 87px;
  height: 21px;
  margin-bottom: 8px;
}

.popup-partners__image-logo {
  min-width: 97px;
  height: 64px; 
  .img-fluid {
    max-height: 64px; 
  }
}
.popup-partners__title {
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  color: #000000;
  margin-bottom: 8px;
  @include media-breakpoint-down(sm) {
    font-size: 20px;
    line-height: 150%;
  }
}
.popup-partners__subtitle {
  font-size: 24px;
  line-height: 28px;
  font-weight: 700;
  color: #000000;
  padding-right: 20px;
  @include media-breakpoint-down(sm) {
    font-size: 20px;
    line-height: 23px;
  }

}


.popup-partners__site-partner {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #167038;
}

.popup-partners__site-partner:hover {
  color: #167038;
  cursor: pointer;
}

.popup-partners__text {
  font-size: 18px;
  line-height: 25px;
  color: #141D30;
  margin: 16px 0;
}
.popup-partners__text-mb {
  margin-bottom: 40px;
  @include media-breakpoint-down(sm) {
    margin-bottom: 32px;
  }
}

.popup-partners__text-gray {
  color: rgba(20, 29, 48, 0.6);
}
.popup-partners__sertificats {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  @include media-breakpoint-down(xs) {
    flex-direction: column;
  }
  .popup-partners__subtitle {
    margin-bottom: 16px;  
  }
}

.popup-partners__sertificats-title {
  font-size: 24px;
  line-height: 28px;
  font-weight: 700;
  color: #000000;
  max-width: 209px;
}

.popup-partners__sertificats-items {
  max-width: 348px;
  overflow-x: scroll;
  display: flex;
  padding-bottom: 10px;

  @media(max-width: 575px) {
    max-width:100%;
  }
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-track {
      background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 20px;
    border: 3px solid #167038;
  }
}
.popup-partners__sertificats-item {
  min-width: 97px;
  min-height: 116px;
  margin-right: 16px;
  border: 1px solid #167038;
}
.popup-partners__sertificats-item:last-child {
  margin-right: 0;
}

.popup-partners__company-items{
  padding-left: 12px;
}
.popup-partners__company-item {
  list-style: none;
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 22px;
  color: rgba(20, 29, 48, 0.6);
  position: relative;
}

.popup-partners__company-item:last-child {
  margin-bottom: 0;
}

.popup-partners__company-item::before {
  content: "";
  width: 4px;
  height: 4px;
  position: absolute;
  top: 10px;
  left: -11px;
  border-radius: 50%;
  background: #167038;

}