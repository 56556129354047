.task-modal-main {
    background: #fff;

    @media (max-width: 1024px) {
        width: 100%;
    }

    .container, .parallax-bg, .task__wrapper, .container-popup {
        max-width: 1905px;
        margin: auto;
    }

    .container-popup {
        padding: 0;
    }
}

.task {
    &__img-wrap {
        width: 100%;
        margin-bottom: 20px;

        .img-wrap{
            width: 100%;
            position: relative;
            cursor: pointer;
            
            .img-fluid {
                width: 100%;
                max-height: 300px;
                height: auto;
            }

            // &:before {
            //     content: '';
            //     position: absolute;
            //     top: 0;
            //     left: 0;
            //     height: 100%;
            //     width: 100%;
            //     background: #167038;
            //     mix-blend-mode: multiply;
            // }

            &:hover {
                &::before {
                    background: transparent;
                    mix-blend-mode: normal; 
                }
            }
        }
    }

    &__title {
        font-weight: 700;
        font-family: $font-family_title;
    }

    &__content {
        padding-top: 32px;
        height: 100%;
        display: flex;
        flex-direction: column;
        position: relative;

        .task__desc-wrap {
            margin-bottom: 50px;
            color: #68676C;

            .mt {
                @include my(20px,0);
            }
        }

        .task__puncts-wrap {
            max-width: 100%;

            .task__items {
                padding: 15px 24px;
                margin-top: 24px;

                @media(min-width: 541px) {
                    max-height: 400px;
                    overflow-y: auto;

                    &::-webkit-scrollbar {
                        width: 6px;
                    }

                    &::-webkit-scrollbar-track {
                        background: transparent;
                    }

                    &::-webkit-scrollbar-thumb {
                        background-color: #167038;
                        border-radius: 5px;
                    }
                }
            }

            @media (max-width: 540px) {
                .task__items {
                    width: 100%;

                    .task__item {
                        padding-bottom: 16px;
                    }
                }
            }
        }

        p {
            padding: 0;
        }
    }

    &__materials {
         display: flex;
         flex-wrap: wrap;

         .task__title {
           width: 100%;
           padding-bottom: 32px;
         }

         .task__subtitle {
            flex-grow: 1;
         }

         .task__materials-wrap {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            width: 100%;

            @media (max-width: 1279px) {
                width: 100%;
            }

            @media (max-width: 768px) {
                flex-direction: column;
            }

            .materials {
                display: flex;
                width: 50%;
                margin-right: 10px;

                .materials__icon, .materials__content {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    align-items: center;
                }

                .materials__icon {
                    min-width: 22%;

                    span {
                        padding: 0 !important;
                    }
                } 

                .materials__content {
                    align-items: flex-start;
                    justify-content: space-between;
                    padding-right: 10px;

                    .materials__subtitle {
                       font-size: 16px;
                    }
                }

                @media (max-width: 768px) {
                    width: 100%;

                    .materials__icon {
                        padding-right: 20px;
                        
                        span {
                            font-size: 12px;
                        }
                    }
                }
            }
         }
    }
}

.task__bg {
    background: #F4F6FB;
    padding: 40px 40px 0;

    @media (max-width: 768px) {
        padding: 32px 20px 32px;
    }

    .container-popup {
        .popup__items {
            .services__col_sm {
                flex: 0 0 50% !important;
                width: 50% !important;
                max-width: 50% !important;

                @media (max-width: 767px) {
                    flex: 0 0 100% !important;
                    width: 100% !important;
                    max-width: 100% !important;
                }
            }
        }
    }
}

.task__pagination {
    display: flex;
    justify-content: center;
    
    .swiper-wrapper {
        width: 261px;
        display: flex;
        justify-content: space-between;

        .task__number {
            width: 5px;
            height: 5px;
            background: rgba(22,112,56,.5);
    
            &.swiper-slide-thumb-active {
                width: 26px!important;
                background: #167038!important;
            }
        }
    }
}

.task__title {
    @media (max-width: 768px) {
        font-size: 20px;
    }
}

.header_modal-task {
    position: sticky !important;
}

.task__wrapper {
    @include media-breakpoint-between(md,xl) {
        display: flex;
        flex-direction: column;
    }
}

.task__col {
    &_left {
        @include media-breakpoint-between(md,xl) {
            @include fw(100%/12*7);
        }
    }

    &_right {
        @include media-breakpoint-between(md,xl) {
            @include fw(100%/12*3);
        }
        @include media-breakpoint-between(xs,md) {
            @include py(37px, 0);
        }
    }
}

.task__items {
    @include media-breakpoint-between(md,xl) {
        @include py(24px, 0);
        @include px(15px,0);
    }
    @include media-breakpoint-between(xs,md) {
        @include py(24px, 0);
        @include px(18px,0);
    }
}

.task__item {
    color: rgba(0, 0, 0, 0.6);
    font-weight: normal;
    @include media-breakpoint-between(md,xl) {
        @include fs-lh(16px,22px);
        @include py(0, 16px);
    }
}

.task__mh {
    min-height: 290px!important;
}