.header {
        top: 0;
    left: 0;
    position: fixed;
    right: 0;
    z-index: 104;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    @include py(16px);
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.5);

    &__wrapper {
        @extend %flex-item;
        justify-content: space-between;
        @include media-breakpoint-down(lg) {
            flex-wrap: wrap;
            row-gap: 16px;
        }
        @include media-breakpoint-down(sm) {
            justify-content: space-between;
        }
    }

    &__icon {
        display: block;
        width: 137px;
        height: 37px;
        .icon-fluid {
            width: 100%;
            height: 100%;
        }
    }
    ul {
        padding: 0;
    }
    .container {
        @include media-breakpoint-down(md) {
            overflow: visible!important;
        }
    }
}


.header__col {
    transition: all .3s ease;

    &_logo {
        @include media-breakpoint-between(lg,xl) {
            @include fw(100%/12*1.5);
        }
        @include media-breakpoint-down(md) {
            @include fw(100%/12*4);
        }

        @include media-breakpoint-down(sm) {
            @include fw(100%/12*3.5);
        }
    }

    &_nav {
        @include media-breakpoint-down(md) {
            display: none;
            //@include fw(100%/12*7);
        }
        //@include media-breakpoint-between(md,xl) {
      //      @include fw(100%/12*7);
      //  }
    }

    &_widget {
        @include media-breakpoint-between(lg,xl) {
            display: flex;
            justify-content: space-between;
            @include fw(100%/12*3);
        }
        @include media-breakpoint-down(lg) {
            display: flex;
            justify-content: space-between;
            @include fw(100%/12*5);
        }
        @include media-breakpoint-down(md) {
            display: flex;
            justify-content: space-between;
            @include fw(100%/12*5);
        }
        @include media-breakpoint-down(sm) {
            display: flex;
            @include fw(100%/12*6);
            justify-content: space-between;
        }
        @include media-breakpoint-down(xs) {
            @include fw(100%/8*3.5);
            justify-content: end;
        }
    }
}

.header-green {
    background: $color__green-tab;
}

.phone-header {
    display: block;
    @include text-header;
    @include media-breakpoint-down(xs) {
        display: none;
    }
}

.dropdown-header {
    display: block;
    @include text-header;
    @include media-breakpoint-down(xs) {
           display: none;
    }
    .icon-fluid {
        width: 10px;
        height: 10px;
    }
}

.lang-header {
    display: block;
    @include text-header;
    border-bottom: 1px dashed $color__white;
}

.burger-header {
    display: none;

    @include media-breakpoint-down(lg) {
        display: block;
        position: relative;
        width: 20px;
        height: 20px;
        .icon-fluid {
            width: 20px;
            height: 10px;
        }
    }
}

.dropdown {
    .btn {
        padding: 0;
    }
    .dropdown-toggle:after {
        display: none;
    }
}
//#dropdownMenu2 {
 //   .icon-fluid {
 //      transform: rotate(-180deg);
  //  }
//}

.header_active {
    padding-top: 20px;
    padding-bottom: 16px;
    background: rgb(22,112,56);
}

.header_active + .burger-menu {
    top: 74px;
}