.video {
    width: 100%;
    height: 340px;
    @include media-breakpoint-down(xs) {
        height: 200px;
    }   
    &__btn {
        display: block;
        cursor: pointer;
        text-align: center;
        &_text {
            display: block;
            @include py(8px,0);
            @include  fs-lh(16px,22px);
            color: $color__white;
            @include media-breakpoint-down(xs) {
                display: none;
            }
        }
        @include media-breakpoint-between(md,xl) {
            position: relative;
            left: 50%;
            transform: translate(-50%, -200%);
        }
        @include media-breakpoint-down(sm) {
            position: relative;
            left: 50%;
            transform: translate(-50%, -200%);
        }
        @include media-breakpoint-down(xs) {
            width: 20%;
        }
    }
    &__prew {
        height: 340px;
        .img-fluid {
            height: 100%;
            width: 100%;
        }
        @include media-breakpoint-down(xs){
            height: 200px;
            width: 100%;
            .img-fluid {
                width: 100%;
            }
        }
    }
}
.video__media {
    @media (min-width: $tablet-l - 1) and (max-width: $desktop-s + 19){
        height: 275px;
    }
    @media (max-width: $tablet-s + 53 ) {
        height: 220px;
    }
    @media (max-width: $mobile + 80) {
        height: 170px;
    }
}