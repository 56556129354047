.burger-menu {
    display: none;
    visibility: hidden;
    opacity: 0;
    z-index: 1040;
    @include media-breakpoint-down(lg) {
        display: block;
        position: fixed;
        right: 0;
        z-index: 1040;
        background: $color__white;
        width: 100%;
        transition: all .6s;
        bottom: 0;
        top: 70px;
    }
    &__wrapper {
        display: none;
        @include media-breakpoint-down(lg) {
            display: flex;
            justify-content: space-between;
            @include py(60px,0);
        }
        @include media-breakpoint-down(sm) {
            display: flex;
            justify-content: space-between;
            @include py(35px,0);
            flex-direction: column-reverse;
        }
    }
    .phone {
        &-header {
            color: $color__black;
            @include media-breakpoint-down(lg) {
                @include fs_lh(24px,33px);
                font-weight: 600;
                @include my(0,24px);
            }
            @include media-breakpoint-down(sm) {
                display: block;
                @include fs_lh(24px,33px);
                font-weight: 400;
                @include my(0,16px);
            }
        }
    }
    .dropdown {
        &-header {  
            color: $color__black;
            @include fs_lh(14px,19px);
            font-weight: 400;
            padding: 0!important;
            @include media-breakpoint-down(sm) {
                display: block;
            }
        }
    }
}
.burger-menu__container {
    @include media-breakpoint-down(md) {
        position: absolute;
        width: 100%;
        height: 100%;
        overflow-x: hidden;
        overflow-y: scroll;
    }
}
.active {
    left: 0;
    visibility: visible;
    opacity: 1!important;
}
.active-map {
    visibility: visible;
    opacity: 1!important;
}
.hide {
    display: none;
    visibility: hidden;
    opacity: 0;
}
.burger-close-img {
    display: none;
}
.show {
    display: block;
    visibility: visible;
    opacity: 1;
}
.burger-menu__col {
    &_left {
        @include media-breakpoint-down(lg) {
            @include fw(100%/8*2.5);
            @include mx(30px,0);
        }
        @include media-breakpoint-down(sm) {
            @include fw(100%/8*4);
            @include mx(0);
            @include my(32px,0);
        }
    }

    &_right {
        @include media-breakpoint-down(lg) {
            @include fw(100%/8*4.5);
        }
        @include media-breakpoint-down(sm) {
            @include fw(100%/8*8);
        }
    }
}
.menu-box  {
    .menu__item {
        list-style-type: none;
        border-bottom: 1px solid $color__grey;

        @include media-breakpoint-down(lg) {
            @include my(0,12px);
        }
    }
    .menu__link {
        display: block;
        font-weight: 700;
        color: $color__black;
        font-family: $font-family_title;
        @include media-breakpoint-down(lg) {
            @include fs_lh(24px,28px);
            @include my(0,16px)
        }
        @include media-breakpoint-down(sm) {
            @include fs_lh(20px,23px);
            @include my(0,12px)
        }
    }
}

.burger-menu_active {
    display: none;
    @include media-breakpoint-down(lg) {
        display: block;
        left: 0;
        opacity: 1;
        visibility: visible;
    }
}
.burger__line::before,
.burger__line::after,
.burger__line {
    position: absolute;
    top: 5px;
    @include wh(100%,0.5px);
    background: $color__white;
    transition: 0.4s;
}
.burger__line::before {
    content: '';
    top: -5px;
}
.burger__line::after {
    content: '';
    top: 5px;
}

.burger__line {
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
}

.burger-menu_active .burger__line {
    background-color: transparent;
}
.burger-menu_active .burger__line::before {
    top: 0;
    transform: rotate(40deg);
}
.burger-menu_active .burger__line::after{
    top: 0;
    transform: rotate(-40deg);
}

.burger-menu__overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10;
}
.fixed {
    position: fixed;
    top: 0;
    z-index: 100;
}

.collaps-body{
    padding: 10px 0 0 0; 
    a {
        display: block;
        color: $color__black;
        @include fs_lh(14px,19px);
        font-weight: 400;
        padding: 0  0 12px 0;
    }
}

.open {
    .icon-fluid {
        transform: rotate(180deg);
    }
}