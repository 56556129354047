.footer {
    position: relative;
    height: fit-content;
    .pg-canvas {
       height: auto;
    }
    @include media-breakpoint-between(md,xl) {
        @include py(60px,70px);
    }
    @include media-breakpoint-down(sm){
        @include py(24px);
    }
    .animate-bg  {
        min-height: 517px;
        height: fit-content;
    }
    &__logo {
        display: block;
        width: 137px;
        height: 37px; 
        .icon-fluid {
            width: 100%;
            height: 100%;
        }
    }
    &__flex {
        @include media-breakpoint-between(lg,xl) {
            display: flex;
            justify-content: space-between;
        }
        @include media-breakpoint-down(md) {
            display: flex;
            justify-content: space-between;
            //flex-wrap: wrap;
        }
        @include media-breakpoint-down(sm){
            flex-direction: column;
        }
    }
    .nav__link {
        @include media-breakpoint-between(md,xl) {
            @include fs_lh(16px,22px);
            @include py(0,13px);
            .icon-fluid {
                position: relative;
                top: -5px;
                @include wh(7px,7px);
            }
        }
        @include media-breakpoint-between(xs,sm) {
            .icon-fluid {
                position: relative;
                top: -5px;
                @include wh(7px,7px);
            }
        }
        @include media-breakpoint-down(sm) {
            @include py(0,12px);
        }
        &_pt {
            @include py(0px,13px);
            @include media-breakpoint-between(xs,lg) {
                //@include py(0);
            }
        }
    }
    .nav__item {
        @include media-breakpoint-down(md) {
            &_dn {
                display: none;
            }
        }
    }
    .nav__item_w {
        padding-right: 0;
    }
    &__policy {
        color: $color__white;
        font-weight: 400;
        @include media-breakpoint-between(lg,xl) {
            position: absolute;
            bottom: 0;
        }
        @include media-breakpoint-down(md) {
            display: block;
            max-width: 191px;
            width: 100%;
        }
        @include media-breakpoint-down(sm) {
            justify-content: left;
            @include py(24px,0);
            font-weight: 300;
        }
    }
    &__develop  {
        color: $color__white;
        @include fs_lh(16px,22px);
        span {
            font-weight: 300;
        }
        @include media-breakpoint-down(sm) {
            width: 60%;
        }
    }
    &__conf  {
        @include py(15px,0);
        @include fs_lh(16px,22px);
        display: flex;
        justify-content: end;
        @include media-breakpoint-down(md) {
           justify-content: left;
        }
        @include media-breakpoint-down(sm) {
            justify-content: left;
            @include py(24px,0);
        }
    }
    &__link {
        display: inline-block;
        color: $color__white;
        border-bottom: 1px solid $color__white;
    }
    &__intro {
        color: $color__white;
        @include fs_lh(14px,19px);
        font-weight: 300;
        a {
            color: $color__white;
            border-bottom: 1px solid $color__white;
        }
        @include media-breakpoint-down(sm) {
            width: 90%;
        }
    }
    &__excerpt {
        display: inline-block;
        @include py(4px,0);
        color: $color__white;
        @include fs_lh(14px,19px);
        border-bottom: 1px solid $color__white;
    }
    .btn {
        margin-left: 40px;
        padding: 16px 40px;
        @include media-breakpoint-down(md) {
            padding: 24px 64px ;
        }
        @include media-breakpoint-down(xs) {
            margin-top: 16px;
            margin-left: 0;
        }
    }
    .nav {
        @include media-breakpoint-down(sm) {
            display: block;
            max-width: 300px;
        }
    }
}
.footer__conf {
    padding-top: 15px;
    padding-bottom: 0;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    justify-content: end;
    white-space: nowrap;
    width: 100%;
    @include media-breakpoint-down(sm) {
        padding: 0;   
        justify-content: start;
    }
    .footer__link {
        @media (min-width:$tablet-s)and (max-width:$tablet-m - 1) {
            max-width: 100%;
        }
     }
}

ul {
    @include media-breakpoint-down(sm) {
        padding: 0;   
    }
}
.nav__flex_item{
    display: flex;
}
.footer__nav_flex {
    @include media-breakpoint-down(sm) {
        display: flex;
        flex-wrap: wrap;
        //@include fw(100%/12*6);
        .nav__link {
            margin-right: 14px;
        }
    }
}
.footer__nav_mobile {
    display: none;

    @media (max-width: $tablet-m - 1) {
        display: block;
    }
}
.footer__nav_desktop {
    display: block;

    @media (max-width: $tablet-m - 1) {
        display: none!important;
    }
}
.footer__wrapper {
    @include media-breakpoint-between(lg,xl) {
        display: flex;
        justify-content: space-between;
    }
    @include media-breakpoint-down(md) {
        display: flex;
        flex-direction: column;
    }
}
.footer__flex_bottom {
    margin-bottom: 105px;
    @media (min-width: $tablet-m) and (max-width: $tablet-l){
        margin-top: 32px;
    }
    @include media-breakpoint-down(md) {
        margin-bottom: 64px;
    }
    @include media-breakpoint-down(xs) {
        margin-bottom: 0px;
    }
}
.footer__col {
    &_logo {
        @include media-breakpoint-between(md,xl) {
            @include fw(100%/12*2);
        }
    }
    &_content {
        // @include media-breakpoint-down(xl) {
        //     @include fw(100%/12*5);
        //     position: relative;
        // }
        // @include media-breakpoint-between(lg,xl) {
        //     @include fw(100%/12*7);
        //     position: relative;
        // }
        // @include media-breakpoint-down(md) {
        //     @include fw(100%/12*12);
        //     @include py(32px,64px);
        // }
        @include media-breakpoint-down(sm) {
            @include py(24px,0);
        }
    }
}

.nav__col {
    @include media-breakpoint-down(sm) {
        @include py(0,12px);
    }
    &_left {
        @include media-breakpoint-down(md) {
            max-width: 380px;
            width: 100%;
            padding: 0;
        }
    }
    &_center {
        @include media-breakpoint-down(md) {
            padding: 0;
        }
        @include media-breakpoint-down(sm) {
            @include fw(100%/8*3);
            margin-right: 14px;
        }
    }
    &_right {
        @include media-breakpoint-down(md) {
            @include px(15px,0);
            .nav__item {
                padding-right: 0!important;
            }
        }
        @include media-breakpoint-down(sm) {
            @include fw(100%/8*3);
            @include px(0px,0)
        }
    }
}
.footer__wrapper_down  {
    display: flex;
    justify-content: space-between;

    @include media-breakpoint-down(sm) {
        // @include py(35px,34px);
        justify-content: start;
    }

    @include media-breakpoint-down(xs) {
        flex-direction: column-reverse;

        // @include py(0,24px);
    }
}

.footer__col {
    &_down_policy {
        @include media-breakpoint-between(sm,xl) {
            @include fw(100%/12*4);
            position: relative;
        }
    }
    &_down_content {
        @include media-breakpoint-between(lg,xl) {
            @include fw(100%/12*4);
            position: relative;
            display: flex;
            justify-content: end;
        }
        @include media-breakpoint-down(md) {
            @include fw(100%/12*6);
            position: relative;
            display: flex;
            justify-content: end;
        }
        @include media-breakpoint-down(sm) {
            justify-content: initial;
            @include fw(100%/12*4);
        }
        @include media-breakpoint-down(xs) {
            justify-content: initial;
            @include fw(100%/8*8);
        }
    }
    &_bottom_left {
        @include media-breakpoint-between(md,xl) {
            @include fw(100%/12*7);
            margin-right: 80px;
            position: relative;
        }
        @include media-breakpoint-down(sm) {
            @include fw(100%/8*5);
        }
        @include media-breakpoint-down(xs) {
            @include fw(100%/8*8);
        }
    }
    &_bottom_right  {
        @include media-breakpoint-between(md,xl) {
            @include fw(100%/12*2);
            position: relative;
        }
        @include media-breakpoint-down(sm) {
            @include fw(100%/8*2);
        }
        @include media-breakpoint-down(xs) {
            @include fw(100%/8*8);
        }
        .btn {
            @include media-breakpoint-down(sm) {
               width: 100%;
               min-height: 44px;
            }
            @include media-breakpoint-down(xs) {
                width: 50%;
                @include my(16px,0);
            }
        }
    }
}

.footer__down {
    @include media-breakpoint-between(sm,xl) {
       display: flex;
    }
}

.footer__bottom {
    border-top: 1px solid #D7D9DB;
    background: #167038;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    z-index: 104;
    width: 100%;
    
    padding: 24px 0 40px 0;
    @include media-breakpoint-down(lg) {
        padding: 25px 0;
    }

    // @include media-breakpoint-down(sm) {
    //     justify-content: space-between;
    // }
    @include media-breakpoint-down(xs) {
        padding: 16px 0 25px 0;
    }
}
.resize {
   position:  relative;
   bottom: 0;
   .pg-canvas {
       bottom: 0;
       height: 100%;
   }
}

.footer__bottom-container {
    justify-content: space-between;
    max-width: 1320px;
    margin: 0 auto;
    padding: 0 25px;
    @include media-breakpoint-between(sm,xl) {
        display: flex;
        
    }
    @include media-breakpoint-down(xs) {
        padding: 0 12px;
    }
}

.footer__about {
    display: flex;
    align-items: end;
    @include media-breakpoint-down(sm) {
        flex-direction: column;
        align-items: center;
        margin-left: 80px;
    }
    @include media-breakpoint-down(xs) {
        margin-left: 0;
    }
}

.footer__cookie {
    padding-top: 15px;
    padding-bottom: 0;
    font-size: 16px;
    line-height: 22px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: end;
    margin-right: 30px;
    width: 100%;
    white-space: nowrap;
    @include media-breakpoint-down(sm) {
        justify-content: start;
        margin-right: 0;
        margin-bottom: 11px;
    }
}